import React, { useEffect, useState } from 'react';
import "./tablas.css";

const _servidorapi = process.env.REACT_APP_URL_API;

const Comestrategias2023 = () => {
    const [datax, setData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi + 'listaparticipacionestrategias2023');
        if (!response.ok) {
          throw new Error(`Error de red: ${response.status}`);
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []);


  return (

    <table class="rwd-table">
  <tr>
    <th>Estrategia</th>
    <th>Obj.General</th>
    <th>Obj.Especifico</th>
    <th>Accion</th>
    <th>Actividad</th>
    <th>Proy.Actividad</th>
    <th>Fuente</th>
    <th>Valor</th>
  </tr>
  {datax.map((asistencia, index) => (
                      <tr key={asistencia.id_proyectoactividadpresupuesto}>
                        <td >{asistencia.estrategia}</td>
                        <td >{asistencia.objetivogeneral}</td>
                        <td >{asistencia.objetivoespecifico}</td>
                        <td >{asistencia.accion}</td>
                        <td >{asistencia.actividad}</td>
                        <td >{asistencia.actividadproyecto}</td>
                        <td >{asistencia.fuente}</td>
                        <td align="right"> ${asistencia.valor.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                      </tr>
                    ))}
</table>


  );
}

export default Comestrategias2023;


