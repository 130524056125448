import React from 'react'
import { Link } from 'react-router-dom'; 
import {BsArchiveFill,BsPersonSquare,BsActivity,BsFillFileTextFill,BsFillMapFill} from 'react-icons/bs'
import {  AreaChart, Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import ComPresupuesto from './Components/ComPresupuesto'
import ComProyectos from './Components/ComProyectos'
import ComContratos from './Components/ComContratos'
import ComEjecuccion from './Components/ComEjecuccion'
const Home = () => {
    const data = [
        {
          name: '2017',
          valor: 4000,
          ejecuccion: 2400,
          amt: 2400,
        },
        {
          name: '2018',
          valor: 3000,
          ejecuccion: 1398,
          amt: 2210,
        },
        {
          name: '2019',
          valor: 2000,
          ejecuccion: 9800,
          amt: 2290,
        },
        {
          name: '2020',
          valor: 2780,
          ejecuccion: 3908,
          amt: 2000,
        },
        {
          name: '2021',
          valor: 1890,
          ejecuccion: 4800,
          amt: 2181,
        },
        {
          name: '2022',
          valor: 2390,
          ejecuccion: 3800,
          amt: 2500,
        },
        {
          name: '2023',
          valor: 3490,
          ejecuccion: 4300,
          amt: 2100,
        },
      ];
      
  return (
    <main className='main-container'>
        <div className='main-title'>
            <h3>ANALYTICS</h3>
        </div>
        <div className='main-cards'>
            <ComProyectos/>   
            <ComContratos/> 
            <ComEjecuccion/> 
            <ComPresupuesto/>

        </div>
        <div className='charts'>
        <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="valor" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="ejecuccion" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="valor" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
      </ResponsiveContainer>
        </div>
    </main>
  )
}

export default Home