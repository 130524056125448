import React, { useEffect, useState } from 'react'
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import md5 from "md5";
import "./Maestros.css";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import classNames from 'classnames'
import { rankItem } from '@tanstack/match-sorter-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faChevronUp,faChevronRight,faAngleDoubleRight,faChevronLeft,faAngleDoubleLeft,faArrowUp,faArrowDown} from '@fortawesome/free-solid-svg-icons';
const optionsacceso =[{ value: 1, label: 'Consulta' },{ value: 2, label: 'Operativo' },{ value: 9, label: 'Administrador' }];
const _servidorapi = process.env.REACT_APP_URL_API
const fuzzyFilter = (row, columnId, value, addMeta) => {
const itemRank = rankItem(row.getValue(columnId), value)
addMeta({ itemRank })
  return itemRank.passed
}
const DebouncedInput = ({ value: keyWord, onChange, ...props }) => {
const [value, setValue] = useState(keyWord);
useEffect(() => {
    const timeout = setTimeout(() => {
      console.log('Filterd');
      onChange(value);
    }, 500);
  
    return () => clearTimeout(timeout);
  }, [onChange, value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
const Usuarios = () => {
  const [isEditMode, setisEditMode] = useState(false); 
  const Swal = require('sweetalert2')
  const [showModal, setShowModal] = useState(false);
  const [nombres, setNombre] = useState('');
  const [clavex, setClave] = useState('');
  const [emailx, setEmail] = useState('');
  const [acceso, setAcceso] = useState('');
  const [id_usuario, setUnidad] = useState('');

  // Inicializar el mapa de Leaflet

  const validateNombres = () => {
    
  };

  const MaestroCreada = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Usuario Creado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestrousuarios');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };
  const MaestroEliminada = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Usuario Eliminado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestrousuarios');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };
  const MaestroActualizado = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'success',
      title: 'Usuario Actualizado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestrousuarios');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };

  const handleAddClick = () => {
    setNombre('');
    setAcceso('');
    setEmail('');
    setClave('');
    setShowModal(true);
    setisEditMode(false);
  };
  const handleEditClick = async (idUsuariox) => {
      setUnidad(idUsuariox)
      const url = _servidorapi+'obtenerusuario/?id_usuario=' + encodeURIComponent(idUsuariox);
      const response = await fetch(url, { method: 'GET' });
      const data2 = await response.json();
      setNombre(data2[0].nombres);
      setAcceso(data2[0].acceso);
      setEmail(data2[0].email);
      setisEditMode(true);
      setShowModal(true);
  };
  const handleEliminarClick = (idUsuariox) => {
    const formdata = new FormData()
    formdata.append('id_usuario', idUsuariox)

    fetch(_servidorapi+'eliminarusuario', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => MaestroEliminada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setAcceso('');
        setEmail('');
        setClave('');
        setShowModal(false);
        setisEditMode(false);

  }


  const handleSaveClick = () => {
    const formdata = new FormData()
    formdata.append('nombres', nombres)
    formdata.append('acceso', acceso)
    formdata.append('email', emailx)
    formdata.append('clave',  md5(clavex))
    fetch(_servidorapi+'crearusuario', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => MaestroCreada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setAcceso('');
        setEmail('');
        setClave('');
        setShowModal(false);
        setisEditMode(false);

  }
  const handleSaveEditClick = () => {
    console.log('Actualizando Usuario:'+id_usuario)
    const formdata = new FormData()
    formdata.append('id_usuario', id_usuario)
    formdata.append('nombres', nombres)
    formdata.append('acceso', acceso)
    formdata.append('email', emailx)

    fetch(_servidorapi+'editarusuario', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => MaestroActualizado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setAcceso('');
        setEmail('');
        setClave('');
        setShowModal(false);
        setisEditMode(false);

  }
  const handleCancelClick = () => {
    // Reiniciar los valores
    setNombre('');
    setAcceso('');
    setEmail('');
    setClave('');
    setShowModal(false);
    setisEditMode(false);
  };

  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  console.log(globalFilter);
  const columns = [
    {
      accessorKey: 'nombres',
      header: () => <span>Nombre</span>,
      cell: info => (
        <div style={{ width: '300px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'email',
      width:300,
      header: () => <span >Email</span>,
      cell: info => (
        <div style={{ width: '500px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
     
    },
    {
      accessorKey: 'id_usuario',
      header: 'Acciones',
      cell: info => {
        const XSetidFidelizado = info.getValue('id_usuario');
        return (
          <div className='d-flex align-items-center'>
          <button className='btn btn-danger' onClick={() => handleEliminarClick(XSetidFidelizado)}>Eliminar</button>
          <button className='btn btn-primary' onClick={() => handleEditClick(XSetidFidelizado)}>Editar</button>
        </div>
        )
      },
      maxWidth: 400,
        minWidth: 140,
        width: 200,
      enableSorting: false
    }
  ]
  const getStateTable = () => {
    const totalRows = table.getFilteredRowModel().rows.length;
    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;
    const rowsPerPage = table.getRowModel().rows.length;

    const firstIndex = (pageIndex * pageSize) + 1;
    const lastIndex = (pageIndex * pageSize) + rowsPerPage;

    return {
      totalRows,
      firstIndex,
      lastIndex
    }
  }
  
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting
    },
    initialState: {
      pagination: {
        pageSize: 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting
  })
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi+'maestrousuarios');
        const jsonData = await response.json();
        setData(jsonData);     
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página
  return (
    <main className='main-containerm'>
    <div className='px-6 py-4'>
    <div className="headerm" style={{ display: 'flex', alignItems: 'center' }}>
    <Button onClick={handleAddClick} variant="primary">+</Button><h1 style={{ display: 'inline-block', marginRight: '10px' }}>Usuarios</h1>
       </div>
      <div className='my-2 d-flex justify-content-end'>
      <div className="position-relative">
  <div className="input-group">
    <DebouncedInput
      type="text"
      value={globalFilter ?? ''}
      onChange={value => setGlobalFilter(String(value))}
      className="form-control"
      placeholder="Buscar..."
    />
    <button className="btn btn-primary" type="button">
      <FontAwesomeIcon icon={faSearch} className="text-light" />
    </button>
  </div>
</div>
      </div> 
      <div className='overflow-auto'>
          <table className='table table-striped w-full min-w-[560px]'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="border-bottom border-gray-300 text-gray-600 bg-gray-100">
                  {headerGroup.headers.map(header => (
                    <th key={header.id} className="py-2 px-4 text-left text-uppercase" style={{
                      width:
                        header.getSize() !== 150 ? header.getSize() : undefined,
                    }}>
                      {header.isPlaceholder
                        ? null
                        :
                        <div
                          className={classNames({
                            'cursor-pointer select-none d-flex justify-content-between': header.column.getCanSort(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FontAwesomeIcon icon={faArrowUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />,
                            desc: <FontAwesomeIcon icon={faArrowDown} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          }[header.column.getIsSorted()] ?? (header.column.getCanSort() ? <FontAwesomeIcon icon={faChevronUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          : null)}
                        </div>
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="text-gray-600 hover:bg-slate-100">
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="py-2 px-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-between space-y-4 text-center'>
          <div className='d-flex align-items-center gap-2'>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faChevronLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
      
            {table.getPageOptions().map((value, key) => (
              <button key={key}
                className={classNames({
                  "btn btn-light rounded border border-gray-300 font-weight-bold disabled:hover:bg-white disabled:hover:text-gray-300": true,
                  "bg-indigo-200 text-indigo-700": value === table.getState().pagination.pageIndex
                })}
                onClick={() => table.setPageIndex(value)}>
                {value + 1}
              </button>
            ))}
      
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}>
            <FontAwesomeIcon icon={faChevronRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}>
              <FontAwesomeIcon icon={faAngleDoubleRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
          </div>
          <div className='text-gray-600 font-weight-bold'>
            {getStateTable().firstIndex}&nbsp;
            a {getStateTable().lastIndex}&nbsp;
            de {getStateTable().totalRows} 
          </div>
          <div className="d-inline-block">
            <select
              className="form-select text-muted border border-muted rounded-pill outline-primary py-2"
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}>
            <option value="5">5 pág.</option>
            <option value="10">10 pág.</option>
            <option value="20">20 pág.</option>
            <option value="25">25 pág.</option>
            <option value="50">50 pág.</option>
          </select>
          </div>
     </div>
     <Modal show={showModal} onHide={() => setShowModal(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode ? 'Editar Usuario' : 'Agregar Usuario'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
           <Form.Group controlId="formName">
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          type="text"
          placeholder="Nombre"
          value={nombres}
          onChange={e => setNombre(e.target.value)}
          onBlur={validateNombres} // Validar al perder el foco del input
        />
        <Form.Control.Feedback type="invalid">
          Por favor, introduce un nombre válido.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formDescripcion2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text" 
              placeholder="email"
              value={emailx} 
              onChange={e => setEmail(e.target.value)} 
            />
          </Form.Group>
          <Form.Group controlId="formUserType5">
              <Form.Label>Nivel Usuario</Form.Label>
              <Form.Control
                as="select"
                value={acceso}
                onChange={e => setAcceso(e.target.value)}
              >
                {optionsacceso.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          <Form.Group controlId="formClave">
            <Form.Label>Clave</Form.Label>
            <Form.Control
             type="password" 
              placeholder="clave"
              value={clavex} 
              onChange={e => setClave(e.target.value)} 
            />
          </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode ? handleSaveEditClick : handleSaveClick}>{isEditMode ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
    </div>
   </main>
  )
}

export default Usuarios