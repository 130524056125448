import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';
import "./Maestros.css";
import "../App.css";
const _servidorapi = process.env.REACT_APP_URL_API
const Contratoactividades = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [isEditMode1, setisEditMode1] = useState(false);
  const [actividadContrato, setaactividadcontrato] = useState([]);
  const [nombre, setNombre] = useState('');
  const [nombrecontrato, setNombrecontrato] = useState('');
  const [valor, setValor] = useState('');
  const [id_actividadcontrato, setIdActividadContrato] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [porcentaje, setPorcentaje] = useState(1);
  const [actividad, setActividad] = useState('');
  const [id_actividad, setIdactividad] = useState('');
  const [optionsActividad, setOptionsActividad] = useState([]);


  const navigate = useNavigate();
  const handlePresupuestoClick = async (idcontratox) => {
    console.log('No ENTRA');
    navigate(`/contrato/contratopresupuesto?idcontratoa=${idcontratox}`);
    
  };

  const actividadCreadaContrato = async () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          icon: 'success',
          title: 'Actividad Registrada',
          text: 'Gracias',
        }).then(async () => {

          const url = _servidorapi+'obtenercontratoactividades?id_contrato=' + encodeURIComponent(idcontratox);
          const response = await fetch(url, { method: 'GET' });
          const data = await response.json();
          setaactividadcontrato(data);
        
        });
      
        return null;
  };

  const ActividadActualizada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Actividad Actualizada',
        text: 'Gracias',
      }).then(async () => {

        const url = _servidorapi+'obtenercontratoactividades?id_contrato=' + encodeURIComponent(idcontratox);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setaactividadcontrato(data);
      });
    
      return null;
  };

  const actividadBorrada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Actividad Borrada',
        text: 'OK',
      }).then(async () => {
        const url = _servidorapi+'obtenercontratoactividades?id_contrato=' + encodeURIComponent(idcontratox);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setaactividadcontrato(data);
      });
    
      return null;
  };

  const faltandatos = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Faltan datos por Llenar',
      text: 'Son Necesarios',
    }).then(async () => {
    });
  
    return null;
  };
  const handleSaveClick1 = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 || valor <= 0  || porcentaje <= 0  || cantidad <= 0  || actividad <= 0) {
      faltandatos();
      return null
    } 
      console.log('La Actividad es:'+ actividad)
      const formdata = new FormData()
      formdata.append('nombre', nombre)
      formdata.append('presupuesto', valor)
      formdata.append('porcentaje', porcentaje)
      formdata.append('cantidad', cantidad)
      formdata.append('descripcion', descripcion)
      formdata.append('id_planactividad', actividad)
      formdata.append('id_contrato', idcontratox)
      fetch(_servidorapi+'crearactividadcontrato', {
        method: 'POST',
        body: formdata
      })
      .then(res => res.text())
      .then(res => actividadCreadaContrato())
      .catch(err => {
        console.error('EL Error es:'+err)
      })
          // Reiniciar los valores
          setNombre('');
          setValor('');
          setActividad('');
          setPorcentaje('');
          setShowModal1(false);
          setisEditMode1(false);
          //fetchActividadProyecto(idcontratox);
  }

  const handleSaveEditClick = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 || valor <= 0  || porcentaje <= 0  || cantidad <= 0  || actividad <= 0) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('id_contratoactividad', id_actividad)
    formdata.append('id_contrato', idcontratox)
    formdata.append('nombre', nombre)
    formdata.append('descripcion', descripcion)
    formdata.append('presupuesto', valor)
    formdata.append('porcentaje', porcentaje)
    formdata.append('cantidad', cantidad)
    formdata.append('id_planactividad', actividad)

    fetch(_servidorapi+'editarcontratoactividad', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ActividadActualizada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setShowModal1(false);
        setisEditMode1(false);

  }
  const handleActividadClick = () => {
    setShowModal1(true);
    setisEditMode1(false);
  };


  const handleEditClick = async (idActividadx) => {
    setIdactividad(idActividadx)
    setIdActividadContrato(idActividadx)
    const url = _servidorapi+'obtenercontratoactividad/?id_contratoactividad=' + encodeURIComponent(idActividadx);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();




    setNombre(data2[0].nombre);
    setDescripcion(data2[0].descripcion);
    setCantidad(data2[0].cantidad)
    setValor(data2[0].presupuesto)
    setPorcentaje(data2[0].porcentaje)
    setActividad(data2[0].id_planactividad)
    setShowModal1(true);
    setisEditMode1(true);
};



  const handleCancelClick = () => {
    // Reiniciar los valores
    setNombre('');
    setShowModal1(false);
    setisEditMode1(false);
  };
 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idcontratox = searchParams.get('idcontratox');
  const Swal = require('sweetalert2')

  async function fetchActividadProyecto(idproyectox) {
    try {
      const response = await fetch(`_servidorapi+'obtenerproyectoactividades/get?id_proyecto=${idproyectox}`, {
        method: 'GET'
      });
  
      if (!response.ok) {
        throw new Error('Error en la solicitud de la API');
      }
  
      const data = await response.json();
      setaactividadcontrato(data);
    } catch (error) {
      console.error(error);
    }
  }
  


  
  const borrarActividad = (xidactividadx) => {
    const formdata = new FormData()
    console.log('La Actividad borrar es la :'+xidactividadx)
    formdata.append('id_contratoactividad', xidactividadx)
    fetch(_servidorapi+'eliminaractividadcontrato', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => actividadBorrada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        


   }

   const ObtenerActividad = async (idactividadx) => {
    const url = _servidorapi+'obtenercontrato/?id_contrato=' + encodeURIComponent(idactividadx);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setNombrecontrato(data2[0].codigo+' :: '+data2[0].nombre);
};

  useEffect(() => {
    ObtenerActividad(idcontratox);
    const fetchData = async () => {
      try {
        const responseActividad = await fetch(_servidorapi+'maestroplanactividadesc');
        const jsonActividad = await responseActividad.json();
        const jsonOptionActividad =  [
          { value: "", label: "Seleccione la activida del Plan de Accion" },
          ...jsonActividad.map(item => ({ value: item.id_planactividad, label: item.nombre })),
        ];
        setOptionsActividad(jsonOptionActividad);
        console.log('Eñ contrato es :'+idcontratox)
        const url = _servidorapi+'obtenercontratoactividades?id_contrato=' + encodeURIComponent(idcontratox);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setaactividadcontrato(data);

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    
    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página

    return (
      <div className='main-container'>
        
      <h1 style={{ display: 'inline' }}>Contrato: {nombrecontrato}</h1>

     
            <div className="card-header">
              <h2 className="h5 d-inline-block">Actividades del contrato</h2>
              <button onClick={handleActividadClick} className="btn btn-primary float-end">Adicionar</button>
            </div>
            <div className="card-content">
            <table className="table table-dark table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Nombre</th>
                <th>Descripcion</th>
                <th>Valor</th>
                <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
              {actividadContrato.map((asistencia, index) => (
                <tr key={asistencia.id_contratoactividad}>
                  <td>{asistencia.nombre}</td>
                  <td>{asistencia.descripcion}</td>
                  <td align="right"> ${asistencia.presupuesto.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                  <td>
                  <button className="btn btn-danger" onClick={() => borrarActividad(asistencia.id_contratoactividad)}>
                    Borrar
                  </button>
                  <button className="btn btn-success" onClick={() => handleEditClick(asistencia.id_contratoactividad)}>
                    Editar
                  </button>
                  <button className='btn btn-info' onClick={() => handlePresupuestoClick(asistencia.id_contratoactividad)}>Presu.</button>
                </td>
                </tr>
              ))}
            </tbody>
              </table>

   
  
    </div>
      <Modal show={showModal1} onHide={() => setShowModal1(false)}>
 <Modal.Header closeButton>
   <Modal.Title>{isEditMode1 ? 'Editar Actividad' : 'Agregar Actividad'}</Modal.Title>
 </Modal.Header>
  <Modal.Body>
    <Form>
    <Form.Group controlId="formUserType">
        <Form.Label>Actividad Plan de Accion</Form.Label>
        <Form.Control
          as="select"
          value={actividad}
          onChange={e => setActividad(e.target.value)}
        >
          {optionsActividad.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          type="text"
          placeholder="Nombre"
          value={nombre}
          onChange={e => setNombre(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formDescripcion">
      <Form.Label>Objeto</Form.Label>
      <Form.Control
        as="textarea" 
        rows={3} 
        placeholder="Objeto General"
        value={descripcion} 
        onChange={e => setDescripcion(e.target.value)} 
      />
    </Form.Group>
      <Form.Group controlId="formPresupuesto">
        <Form.Label>Presupuesto</Form.Label>
        <Form.Control
          type="text"
          placeholder="Presupuesto"
          value={valor}
          onChange={e => setValor(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formCelular">
        <Form.Label>Cantidad</Form.Label>
        <Form.Control
          type="text"
          placeholder="cantidad"
          value={cantidad}
          onChange={e => setCantidad(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formCedula">
        <Form.Label>Porcentaje</Form.Label>
        <Form.Control
          type="text"
          placeholder="Porcentaje"
          value={porcentaje}
          onChange={e => setPorcentaje(e.target.value)}
        />
      </Form.Group>  
      </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
    <Button variant="primary" onClick={isEditMode1 ? handleSaveEditClick : handleSaveClick1}>{isEditMode1 ? 'Actualizar' : 'Guardar'}</Button>
  </Modal.Footer>
</Modal>


    </div>
    );
}

export default Contratoactividades

