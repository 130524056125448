import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import "./Maestros.css";
import "../App.css";
//const _servidorapi = 'https://server2.consultoriasoficiales.com/'
const _servidorapi = process.env.REACT_APP_URL_API

const Objetivospepresupuesto = () => {
    
  const [showModal1, setShowModal1] = useState(false);
  const [isEditMode1, setisEditMode1] = useState(false);
  const [actividadpresupuesto, setActividadpresupuesto] = useState([]);
  const [valor, setValor] = useState('');
  const [id_presupuesto, setPresupuesto] = useState('');
  const [id_actividadpresupuesto, setIdActividadpresupuesto] = useState('');
  const [optionsPresupuesto, setOptionsPresupuesto] = useState([]);
  const [nombreactividad, setNombreActividad] = useState('');
  const [valorFormateado, setValorFormateado] = useState(''); // Valor formateado para mostrar al usuario

  const cpf = (v) => {
    v = v.replace(/([^0-9\.]+)/g, '');
    v = v.replace(/^[\.]/, '');
    v = v.replace(/[\.][\.]/g, '');
    v = v.replace(/\.(\d)(\d)(\d)/g, '.$1$2');
    v = v.replace(/\.(\d{1,2})\./g, '.$1');
    v = v.toString().split('').reverse().join('').replace(/(\d{3})/g, '$1,');
    v = v.split('').reverse().join('').replace(/^[\,]/, '');
    return v;
  };

  const handleValorChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = cpf(inputValue);
    setValor(formattedValue);
  };

  const validateValor = () => {
    if (valor !== '' && (parseInt(valor) < 0)) {
      // Realizar acciones si el valor no es válido, como mostrar un mensaje de error
      document.getElementById("pv").innerHTML = "Debe ser mayor o igual a cero";

      
    } else {
    document.getElementById("pv").innerHTML = "";}
  };

  const faltandatos = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Faltan datos por Llenar',
      text: 'Son Necesarios',
    }).then(async () => {
    });
  
    return null;
  };

  const actividadCreadaContrato = async () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          icon: 'success',
          title: 'Presupuesto Adicionado',
          text: 'Gracias',
        }).then(async () => {

          const url = _servidorapi+'maestroobjetivopepresupuesto?id_actividad=' + encodeURIComponent(idactividadx);
          const response = await fetch(url, { method: 'GET' });
          const data = await response.json();
          setActividadpresupuesto(data);
        
        });
      
        return null;
  };

  const ActividadActualizada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Presupuetso Modificado',
        text: 'Gracias',
      }).then(async () => {

        const url = _servidorapi+'maestroobjetivopepresupuesto?id_actividad=' + encodeURIComponent(idactividadx);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setActividadpresupuesto(data);
      });
    
      return null;
  };

  const actividadBorrada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Presupuesto Borrado',
        text: 'OK',
      }).then(async () => {
        const url = _servidorapi+'maestroobjetivopepresupuesto?id_actividad=' + encodeURIComponent(idactividadx);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setActividadpresupuesto(data);
      });
    
      return null;
  };

  const handleSaveClick1 = () => {
      const numericValue = parseFloat(valor.replace(/,/g, ''));
      if (id_presupuesto <= 0 || numericValue <= 0 ) {
        faltandatos();
        return null
      } 
      const formdata = new FormData()
      formdata.append('id_presupuesto', id_presupuesto)
      formdata.append('valor', numericValue)
      formdata.append('id_actividad', idactividadx)
      fetch(_servidorapi+'crearobjetivopepresupuesto', {
        method: 'POST',
        body: formdata
      })
      .then(res => res.text())
      .then(res => actividadCreadaContrato())
      .catch(err => {
        console.error('EL Error es:'+err)
      })
          // Reiniciar los valores
          setPresupuesto('');
          setValor('');
          setShowModal1(false);
          setisEditMode1(false);
          //fetchActividadProyecto(idcontratox);
  }

  const handleSaveEditClick = () => {
    const numericValue = parseFloat(valor.replace(/,/g, ''));
    if (id_presupuesto <= 0 || numericValue <= 0 ) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('id_actividadpresupuesto', id_actividadpresupuesto)
    formdata.append('valor', numericValue)
    formdata.append('id_presupuesto', id_presupuesto)

    fetch(_servidorapi+'editarobjetivopepresupuesto', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ActividadActualizada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setShowModal1(false);
        setisEditMode1(false);

  }
  const handleActividadClick = () => {
    setShowModal1(true);
    setisEditMode1(false);
  };


  const handleEditClick = async (idActividadx) => {
    setIdActividadpresupuesto(idActividadx)
    const url = _servidorapi+'obtenerobjetivopepresupuesto/?id_actividadpresupuesto=' + encodeURIComponent(idActividadx);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setPresupuesto(data2[0].id_presupuesto);
    setValor(data2[0].valor)
    setShowModal1(true);
    setisEditMode1(true);
};


const ObtenerActividad = async (idactividadx) => {
    const url = _servidorapi+'obtenerobjetivope/?id_objetivope=' + encodeURIComponent(idactividadx);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setNombreActividad(data2[0].nombre);
};




  const handleCancelClick = () => {
    // Reiniciar los valores
    setShowModal1(false);
    setisEditMode1(false);
  };
 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idactividadx = searchParams.get('idactividadx');
  const Swal = require('sweetalert2')

  async function fetchActividadProyecto(idproyectox) {
    try {
      const response = await fetch(`_servidorapi+'maestroobjetivopepresupuesto/get?id_actividad=${idactividadx}`, {
        method: 'GET'
      });
  
      if (!response.ok) {
        throw new Error('Error en la solicitud de la API');
      }
  
      const data = await response.json();
      setActividadpresupuesto(data);
    } catch (error) {
      console.error(error);
    }
  }
  


  
  const borrarActividad = (xidactividadx) => {
    const formdata = new FormData()
    console.log('La Actividad borrar es la :'+xidactividadx)
    formdata.append('id_actividadpresupuesto', xidactividadx)
    fetch(_servidorapi+'eliminarobjetivopepresupuesto', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => actividadBorrada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores



   }


  useEffect(() => {
    ObtenerActividad(idactividadx);
    const fetchData = async () => {
      try {
        const responsePresupuesto = await fetch(_servidorapi+'maestropresupuesto');
        const jsonPresupuesto = await responsePresupuesto.json();
        const jsonOptionPresupuesto =  [
          { value: "", label: "Seleccione el presupuesto" },
          ...jsonPresupuesto.map(item => ({ value: item.id_presupuesto, label: item.vigencia+' - '+item.fuente })),
        ];
        setOptionsPresupuesto(jsonOptionPresupuesto);
        console.log('Eñ contrato es :'+idactividadx)
        const url = _servidorapi+'maestroobjetivopepresupuesto?id_actividad=' + encodeURIComponent(idactividadx);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setActividadpresupuesto(data);

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    
    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página

    return (
      <div className='main-container'>
        
        <h1 style={{ display: 'inline' }}>Objetivo: {nombreactividad}</h1>
 
       
              <div className="card-header">
                <h2 className="h5 d-inline-block">Presupuesto Asignado</h2>
                <button onClick={handleActividadClick} className="btn btn-primary float-end">Adicionar</button>
              </div>
              <div className="card-content">
              <table className="table table-dark table-striped table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th className="col-1">Vigencia</th>
                      <th className="col-5">Fuente</th>
                      <th className="col-3" style={{ textAlign: 'right' }}>Valor</th>
                      <th className="col-3" ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {actividadpresupuesto.map((asistencia, index) => (
                      <tr key={asistencia.id_objetivopepresupuesto}>
                        <td >{asistencia.vigencia}</td>
                        <td style={{ width: '20%', whiteSpace: 'nowrap' }}>{asistencia.fuente}</td>
                        <td align="right"> ${asistencia.valor.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
                        <td>
                        <div className="d-flex justify-content-center">
  <div className="btn-group">
    <button className="btn btn-danger" onClick={() => borrarActividad(asistencia.id_objetivopepresupuesto)}>
      Borrar
    </button>
    <button className="btn btn-success" onClick={() => handleEditClick(asistencia.id_objetivopepresupuesto)}>
      Editar
    </button>
  </div>
</div>
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

     
    
      </div>
      <Modal show={showModal1} onHide={() => setShowModal1(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode1 ? 'Editar Presupuesto' : 'Agregar Presupuesto'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="formUserType">
              <Form.Label>Presupuesto</Form.Label>
              <Form.Control
                as="select"
                value={id_presupuesto}
                onChange={e => setPresupuesto(e.target.value)}
              >
                {optionsPresupuesto.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formValor">
        <Form.Label>Valor</Form.Label>
        <Form.Control
          type="text"
          placeholder="Valor"
          value={valor}
          onChange={handleValorChange}
          onBlur={validateValor}
        />
      </Form.Group>
      <font color='red'>
    <strong>
        <p id="pv"></p>
    </strong>
</font>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode1 ? handleSaveEditClick : handleSaveClick1}>{isEditMode1 ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
 

      </div>
    );
}

export default Objetivospepresupuesto
