import React, { useState } from 'react';
import { BsFillJournalBookmarkFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ImMenu } from 'react-icons/im';
import {Link} from 'react-router-dom'
import Logo from './imagenes/logo_ansv.png'; 
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa el archivo CSS de Bootstrap



function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [planAccionSubMenuOpen, setPlanAccionSubMenuOpen] = useState(false);
  const [planSubMenuOpen, setPlanSubMenuOpen] = useState(false);
  const [presupuestoSubMenuOpen, setPresupuestoSubMenuOpen] = useState(false);
  const [ejecucionSubMenuOpen, setEjecucionSubMenuOpen] = useState(false);
  const [controlSubMenuOpen, setControlSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const togglePlanSubMenu = () => {
    setPlanSubMenuOpen(!planSubMenuOpen);
  };

  const togglePlanAccionSubMenu = () => {
    setPlanAccionSubMenuOpen(!planAccionSubMenuOpen);
  };

  const togglePresupuestoSubMenu = () => {
    setPresupuestoSubMenuOpen(!presupuestoSubMenuOpen);
  };

  const toggleEjecucionSubMenu = () => {
    setEjecucionSubMenuOpen(!ejecucionSubMenuOpen);
  };

  const toggleControlSubMenu = () => {
    setControlSubMenuOpen(!controlSubMenuOpen);
  };
  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
      <div className='sidebar-title'>
      <div className='sidebar-brand'>
  <Link to="/" className="brand-link">
  <img src={Logo} />
  </Link>
</div>
        <span className='icon close_icon' onClick={OpenSidebar}>X</span>
      </div>
      <ul className='sidebar-list'>
        <li className={`sidebar-list-item ${subMenuOpen ? 'active' : ''}`}>
          <a href="#" onClick={toggleSubMenu}>
            <ImMenu className='icon'/> MAESTROS
          </a>
          {/* Subnivel */}
          <ul className={`sublist ${subMenuOpen ? 'open' : ''}`}>
            <li className='sublist-item'>
            <Link to="/tiposcontratos" style={{ color: 'white', textDecoration: 'none' }}>
              Tipos Contratos
            </Link>
            </li>
          <li className='sublist-item'>
          <Link to="/unidades" style={{ color: 'white', textDecoration: 'none' }}>
              Unidades
            </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/fuentes" style={{ color: 'white', textDecoration: 'none' }}>
              Fuentes Fin.
            </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/dependencias" style={{ color: 'white', textDecoration: 'none' }}>
              Dependencias
            </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/responsables" style={{ color: 'white', textDecoration: 'none' }}>
              Responsables
            </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/contratistas" style={{ color: 'white', textDecoration: 'none' }}>
              Contratistas
            </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/presupuesto" style={{ color: 'white', textDecoration: 'none' }}>
              Presupuesto
            </Link>
            </li>
            {/* Agrega más subelementos si es necesario */}
          </ul>
        </li>
        <li className={`sidebar-list-item ${planAccionSubMenuOpen ? 'active' : ''}`}>
          <a href="#" onClick={togglePlanAccionSubMenu}>
            <ImMenu className='icon'/> PNSV
          </a>
          {/* Subnivel */}
          <ul className={`sublist ${planAccionSubMenuOpen ? 'open' : ''}`}>
          <li className='sublist-item'>
            <Link to="/estrategias" style={{ color: 'white', textDecoration: 'none' }}>
      Estrategias
    </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/objetivosgenerales" style={{ color: 'white', textDecoration: 'none' }}>
      Obj. Generales
    </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/objetivosespecificos" style={{ color: 'white', textDecoration: 'none' }}>
      Obj. Especificos
    </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/accion" style={{ color: 'white', textDecoration: 'none' }}>
      Acciones
    </Link>
    <li className='sublist-item'>
            <Link to="/actividades" style={{ color: 'white', textDecoration: 'none' }}>
      Actividades
    </Link>
            </li>
            </li>
            {/* Agrega más subelementos si es necesario */}
          </ul>
        </li>
        <li className='sidebar-list-item2'>
        <Link to="/objetivospe" style={{ color: 'white', textDecoration: 'none' }}>
            <BsFillJournalBookmarkFill className='icon'/> PLAN ESTRATEGICO
            </Link>
        </li>
        <li className='sidebar-list-item'>
        <Link to="/proyectos" style={{ color: 'white', textDecoration: 'none' }}>
            <BsFillJournalBookmarkFill className='icon'/> PROYECTOS DE INVERSION
            </Link>
        </li>
        <li className={`sidebar-list-item ${planSubMenuOpen ? 'active' : ''}`}>
          <a href="#" onClick={togglePlanSubMenu}>
            <ImMenu className='icon'/> PLAN DE ACCION
          </a>
        <ul className={`sublist $planSubMenuOpen ? 'open' : ''}`}>
            <li className='sublist-item'>
            <Link to="/planobjetivos" style={{ color: 'white', textDecoration: 'none' }}>
      Objetivos
    </Link>
            </li>
          

            {/* Agrega más subelementos si es necesario */}
        </ul>
        </li>
        <li className={`sidebar-list-item ${presupuestoSubMenuOpen ? 'active' : ''}`}>
          <a href="#" onClick={togglePresupuestoSubMenu}>
            <ImMenu className='icon'/> CONTRATOS Y ACTIVIDADES
          </a>
        </li>
        <ul className={`sublist ${presupuestoSubMenuOpen ? 'open' : ''}`}>
            <li className='sublist-item'>
            <Link to="/contratos" style={{ color: 'white', textDecoration: 'none' }}>
      Contratos
    </Link>
            </li>
            
    <li className='sublist-item'>
            <Link to="/actividadesoperativas" style={{ color: 'white', textDecoration: 'none' }}>
        Actividades Operativas
    </Link>
    </li>
            {/* Agrega más subelementos si es necesario */}
        </ul>
        <li className={`sidebar-list-item ${ejecucionSubMenuOpen ? 'active' : ''}`}>
          <a href="#" onClick={toggleEjecucionSubMenu}>
            <ImMenu className='icon'/> AVANCES
          </a>
        </li>
        <ul className={`sublist ${ejecucionSubMenuOpen ? 'open' : ''}`}>
            <li className='sublist-item'>
            <Link to="/avancescontratos" style={{ color: 'white', textDecoration: 'none' }}>
      Contratos
    </Link>
            </li>
            <li className='sublist-item'>
            <Link to="/avancesoperativos" style={{ color: 'white', textDecoration: 'none' }}>
      Actividades Operativas
    </Link>
            </li>

            {/* Agrega más subelementos si es necesario */}
          </ul>
          <li className={`sidebar-list-item ${controlSubMenuOpen ? 'active' : ''}`}>
          <a href="#" onClick={toggleControlSubMenu}>
            <ImMenu className='icon'/> DATOS
          </a>
        </li>
        <ul className={`sublist ${controlSubMenuOpen ? 'open' : ''}`}>
        <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Seguimiento
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link to="/contratosestados" className="dropdown-item">
            Contratos
    </Link>
                <Link to="/plandos" className="dropdown-item">
                Actividades Operativas
                </Link>
                <Link to="/plandos" className="dropdown-item">
              PNSV Acciones
                </Link>
                <Link to="/plandos" className="dropdown-item">
              Proyectos
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Ejec. por año del PNSV por :
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to="/estadisticaestrategia2023" className="dropdown-item">
                   Estrategias
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Objetivos General de estrategia
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Objetivos especifico de objetivos general
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Acción de objetivo especifico
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Actividad 
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Acumulado PNSV por :
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to="/plandos" className="dropdown-item">
                   Estrategia
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Objetivos General de estrategia
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Objetivos especifico de objetivos general
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Acción de objetivo especifico
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Actividad 
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Ejecucion por Añor :
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to="/plandos" className="dropdown-item">
                Por proyecto de inversión
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Por actividad de proyecto de inversión
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Objetivo plan estratégico
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Acumulado de ejecución
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link to="/plandos" className="dropdown-item">
                Por proyecto de inversión
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Por actividad de proyecto de inversión
                </Link>
                <Link to="/plandos" className="dropdown-item">
              Objetivo plan estratégico
                </Link>
                <Link to="/plandos" className="dropdown-item">
                Actividad de plan de accion
                </Link>
              </div>
            </li>




            {/* Agrega más subelementos si es necesario */}
          </ul>
      </ul>
    </aside>
  );
}

export default Sidebar;
