import React, { useEffect, useState } from 'react'
import "./Header.css";
import md5 from "md5";
import { NavLink } from 'react-router-dom';
import { Dropdown,Button, Modal, Form } from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import 'bootstrap/dist/css/bootstrap.min.css';
import 
 {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify}
 from 'react-icons/bs'
const nivel_usuario = localStorage.getItem('nivel');

const _servidorapi = process.env.REACT_APP_URL_API
function Header({OpenSidebar}) {
  console.log(nivel_usuario)
  const Swal = require('sweetalert2')
  const [showModal, setShowModal] = useState(false);
  const [c_actual, setActual] = useState('');
  const [id_usuariox,setUsuario] = useState('');
  const [c_nueva, setNueva] = useState('');
  const [isEditMode, setisEditMode] = useState(false); 
  const handleAddClick = () => {
    setShowModal(true);
    setisEditMode(false);
  };

  const claveActualizada = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'success',
      title: 'Clave Actualizad',
      text: 'Gracias',
    }).then(async () => {

    });
  
    return null;
  };
  const handleClick = () => {
    localStorage.setItem('logueadoco', 'false');
    window.location.href = '/'; 
  };
  
  const validateactual = () => {
    //if (vigencia !== '' && (parseInt(vigencia) < 2017 || parseInt(vigencia) > 2031)) {
      // Realizar acciones si el valor no es válido, como mostrar un mensaje de error
    //  document.getElementById("pw").innerHTML = "Debe estar entre 2017 y 2031";

      
   // } else {
   // document.getElementById("pw").innerHTML = "";}
  };

  const validateanterior = () => {
    //if (vigencia !== '' && (parseInt(vigencia) < 2017 || parseInt(vigencia) > 2031)) {
      // Realizar acciones si el valor no es válido, como mostrar un mensaje de error
    //  document.getElementById("pw").innerHTML = "Debe estar entre 2017 y 2031";

      
   // } else {
   // document.getElementById("pw").innerHTML = "";}
  };

  const handleCancelClick = () => {
    // Reiniciar los valores
    setShowModal(false);
    setisEditMode(false);
  };

    
  const claveno = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'La clave anterior no es valida',
      text: 'Gracias',
    }).then(async () => {
    });
    return null;
  };

  const claveactualizada = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Clave Actualizad',
      text: 'Gracias',
    }).then(async () => {
    });
    return null;
  };

  const handleSaveClick = async (event) => {
    const emailUsu = localStorage.getItem('emailusuario');
    const url = _servidorapi+'loginco/?email=' + encodeURIComponent(emailUsu);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    const claveant = data2[0].clave;
    setUsuario(data2[0].id_usuario);
    const claveant1 = md5(c_actual);
    const claveact1 = md5(c_nueva);
    console.log('email_usuario:'+emailUsu)
    console.log('Clave Anterior:'+claveant)
    console.log('Clave Digitada:'+claveant1)
    if (claveant1 == claveant) {
      console.log('Actualizando Usuario:'+id_usuariox)
      const formdata = new FormData()
      formdata.append('id_usuario', id_usuariox)
      formdata.append('clave', claveact1) 
      fetch(_servidorapi+'editarusuarioclave', {
        method: 'POST',
        body: formdata
      })
      .then(res => res.text())
      .then(res => claveactualizada())
      .catch(err => {
        console.error(err)
      })
      setShowModal(false);
    setisEditMode(false)
      
    } else {
      claveno();
    }
  };

  return (
    <header className='header'>
  <div className='menu-icon'>
    <BsJustify className='icon' onClick={OpenSidebar} />
  </div>
  <div className='header-left'>
    <a>::PLAN NACIONAL DE SEGURIDAD VIAL 2022-2031::TABLERO DE CONTROL</a>
  </div>
  <div className='header-right'>
    <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Menú
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {nivel_usuario === '9' && (
          <Dropdown.Item as={NavLink} to="/usuarios">
          Usuarios
        </Dropdown.Item>
        )}
        <Dropdown.Item onClick={handleAddClick}href="#/action-1">Cambiar Clave</Dropdown.Item>
        <Dropdown.Item onClick={handleClick} href="#/action-2">Salir</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
  <Modal show={showModal} onHide={() => setShowModal(false)}>
       <Modal.Header closeButton>
         <Modal.Title>Cambio de Clave</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="formName">
             <Form.Label>Clave Actual</Form.Label>
             <Form.Control
              type="text"
              placeholder="Actual"
              value={c_actual}
              onChange={e => setActual(e.target.value)} 
              onBlur={validateanterior}
              
              />
                    <font color='red'>
                <strong>
                    <p id="pwac"></p>
                </strong>
            </font>
           </Form.Group>
           <Form.Group controlId="formName">
             <Form.Label>Nueva Clave</Form.Label>
             <Form.Control
              type="text"
              placeholder="Nueva Clave"
              value={c_nueva}
              onChange={e => setNueva(e.target.value)} 
              onBlur={validateactual}
              
              />
                    <font color='red'>
                <strong>
                    <p id="pwan"></p>
                </strong>
            </font>
           </Form.Group>

          <font color='red'>
    <strong>
        <p id="pv"></p>
    </strong>
</font>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={handleSaveClick}>Actualizar</Button>
        </Modal.Footer>
      </Modal>
</header>

  )
}

export default Header
