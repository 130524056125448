
import Principal from './Principal'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const isLoggedInK = localStorage.getItem('logueadoco') === 'true';
localStorage.setItem('nivel', 0);

if (!isLoggedInK) {
  localStorage.setItem('logueadoco', 'false');
}

function App() {
  return (
    <div >
      <Principal/>
    </div>
  )
}

export default App
