import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import classNames from 'classnames'
import { rankItem } from '@tanstack/match-sorter-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faChevronUp,faChevronRight,faAngleDoubleRight,faChevronLeft,faAngleDoubleLeft,faArrowUp,faArrowDown} from '@fortawesome/free-solid-svg-icons';

const _servidorapi = process.env.REACT_APP_URL_API

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)

  addMeta({ itemRank })

  return itemRank.passed
}

const DebouncedInput = ({ value: keyWord, onChange, ...props }) => {
  const [value, setValue] = useState(keyWord);
  // console.log(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log('Filterd');
      onChange(value);
    }, 500);
  
    return () => clearTimeout(timeout);
  }, [onChange, value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

const Contratosestados = () => {




  const navigate = useNavigate();
  const handleActividadClick = async (idcontratox) => {
    console.log('No ENTRA');
    navigate(`/contrato/contratoactividad?idcontratox=${idcontratox}`);
    
};


  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  console.log(globalFilter);

  const columns = [
    {
      accessorKey: 'codigo',
      header: () => <span>Contrato</span>,
      cell: info => <span className='font-bold'>{info.getValue()}</span>
    },
    {
      accessorKey: 'nombre',
      header: () => <span>Nom.Corto</span>,
      cell: info => (
        <div style={{ width: '200px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'valor',
      header: () => <span>Valor</span>,
      cell: info => (
        <div style={{ width: '150px', textAlign: 'right' }}>
  <span className='font-bold'>
    {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(info.getValue())}
  </span>
</div>
      ),
    },
    {
        accessorKey: 'ptoxactividades',
        header: () => <span>Por Actividades</span>,
        cell: info => (
          <div style={{ width: '150px', textAlign: 'right' }}>
    <span className='font-bold'>
      {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(info.getValue())}
    </span>
  </div>
        ),
      },
      {
        accessorKey: 'ptoxvigencias',
        header: () => <span>Por Vigencias</span>,
        cell: info => (
          <div style={{ width: '150px', textAlign: 'right' }}>
    <span className='font-bold'>
      {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(info.getValue())}
    </span>
  </div>
        ),
      },
  ]

  const getStateTable = () => {
    const totalRows = table.getFilteredRowModel().rows.length;
    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;
    const rowsPerPage = table.getRowModel().rows.length;

    const firstIndex = (pageIndex * pageSize) + 1;
    const lastIndex = (pageIndex * pageSize) + rowsPerPage;

    return {
      totalRows,
      firstIndex,
      lastIndex
    }
  }
  
   
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting
    },
    initialState: {
      pagination: {
        pageSize: 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi+'maestrocontratosestados');
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página


  return (
    <div className="container">
    <div className='px-6 py-4'>
      <div className='my-2 d-flex justify-content-end'>
      <div className="position-relative">
  <div className="input-group">
    <DebouncedInput
      type="text"
      value={globalFilter ?? ''}
      onChange={value => setGlobalFilter(String(value))}
      className="form-control"
      placeholder="Buscar..."
    />
    <button className="btn btn-primary" type="button">
      <FontAwesomeIcon icon={faSearch} className="text-light" />
    </button>
  </div>
</div>
      </div> 
      <div className='overflow-auto'>
          <table className='table table-striped w-full min-w-[560px]'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="border-bottom border-gray-300 text-gray-600 bg-gray-100">
                  {headerGroup.headers.map(header => (
                    <th key={header.id} className="py-2 px-4 text-left text-uppercase">
                      {header.isPlaceholder
                        ? null
                        :
                        <div
                          className={classNames({
                            'cursor-pointer select-none d-flex justify-content-between': header.column.getCanSort(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FontAwesomeIcon icon={faArrowUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />,
                            desc: <FontAwesomeIcon icon={faArrowDown} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          }[header.column.getIsSorted()] ?? (header.column.getCanSort() ? <FontAwesomeIcon icon={faChevronUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          : null)}
                        </div>
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="text-gray-600 hover:bg-slate-100">
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="py-2 px-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-between space-y-4 text-center'>
          <div className='d-flex align-items-center gap-2'>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faChevronLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
      
            {table.getPageOptions().map((value, key) => (
              <button key={key}
                className={classNames({
                  "btn btn-light rounded border border-gray-300 font-weight-bold disabled:hover:bg-white disabled:hover:text-gray-300": true,
                  "bg-indigo-200 text-indigo-700": value === table.getState().pagination.pageIndex
                })}
                onClick={() => table.setPageIndex(value)}>
                {value + 1}
              </button>
            ))}
      
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}>
            <FontAwesomeIcon icon={faChevronRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}>
              <FontAwesomeIcon icon={faAngleDoubleRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
          </div>
          <div className='text-gray-600 font-weight-bold'>
            {getStateTable().firstIndex}&nbsp;
            a {getStateTable().lastIndex}&nbsp;
            de {getStateTable().totalRows} 
          </div>
          <div className="d-inline-block">
            <select
              className="form-select text-muted border border-muted rounded-pill outline-primary py-2"
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}>
            <option value="5">5 pág.</option>
            <option value="10">10 pág.</option>
            <option value="20">20 pág.</option>
            <option value="25">25 pág.</option>
            <option value="50">50 pág.</option>
          </select>
          </div>
     </div>
    </div>
    </div>
  )
}

export default Contratosestados