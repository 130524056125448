import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import "./Maestros.css";
import "../App.css";
//const _servidorapi = 'https://server2.consultoriasoficiales.com/'
const _servidorapi = process.env.REACT_APP_URL_API

const Actividadesporcentajes = () => {
    
  const [showModal1, setShowModal1] = useState(false);
  const [isEditMode1, setisEditMode1] = useState(false);
  const [actividadpresupuesto, setActividadpresupuesto] = useState([]);
  const [vigencia, setVigencia] = useState('');
  const [porcentaje, setPorcentaje] = useState('');
  const [id_actividadporcentaje, setIdActividadporcentaje] = useState('');
  const [nombreactividad, setNombreActividad] = useState('');

  const actividadCreadaContrato = async () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          icon: 'success',
          title: 'Porcentaje Adicionado',
          text: 'Gracias',
        }).then(async () => {

          const url = _servidorapi+'maestroactividadporcentaje?id_actividad=' + encodeURIComponent(idactividadx);
          const response = await fetch(url, { method: 'GET' });
          const data = await response.json();
          setActividadpresupuesto(data);
        
        });
      
        return null;
  };

  const ActividadActualizada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Porcentaje Modificado',
        text: 'Gracias',
      }).then(async () => {

        const url = _servidorapi+'maestroactividadporcentaje?id_actividad=' + encodeURIComponent(idactividadx);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setActividadpresupuesto(data);
      });
    
      return null;
  };

  const actividadBorrada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Porcentaje Borrado',
        text: 'OK',
      }).then(async () => {
        const url = _servidorapi+'maestroactividadporcentaje?id_actividad=' + encodeURIComponent(idactividadx);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setActividadpresupuesto(data);
      });
    
      return null;
  };

  const handleSaveClick1 = () => {
      const formdata = new FormData()
      formdata.append('vigencia', vigencia)
      formdata.append('id_actividad', idactividadx)
      formdata.append('porcentaje', porcentaje)
      fetch(_servidorapi+'crearactividadporcentaje', {
        method: 'POST',
        body: formdata
      })
      .then(res => res.text())
      .then(res => actividadCreadaContrato())
      .catch(err => {
        console.error('EL Error es:'+err)
      })
          // Reiniciar los valores
          setVigencia('');
          setPorcentaje('');
          setShowModal1(false);
          setisEditMode1(false);
          //fetchActividadProyecto(idcontratox);
  }

  const handleSaveEditClick = () => {
    const formdata = new FormData()
    formdata.append('id_actividadporcentaje', id_actividadporcentaje)
    formdata.append('vigencia', vigencia)
    formdata.append('porcentaje', porcentaje)

    fetch(_servidorapi+'editaractividadporcentaje', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ActividadActualizada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setShowModal1(false);
        setisEditMode1(false);

  }
  const handleActividadClick = () => {
    setShowModal1(true);
    setisEditMode1(false);
  };


  const handleEditClick = async (idActividadx) => {
    setIdActividadporcentaje(idActividadx)
    const url = _servidorapi+'obteneractividadporcentaje/?id_actividadporcentaje=' + encodeURIComponent(idActividadx);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setVigencia(data2[0].vigencia);
    setPorcentaje(data2[0].porcentaje)
    setShowModal1(true);
    setisEditMode1(true);
};


const ObtenerActividad = async (idactividadx) => {
    const url = _servidorapi+'obteneractividad/?id_actividad=' + encodeURIComponent(idactividadx);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setNombreActividad(data2[0].nombre);
};




  const handleCancelClick = () => {
    // Reiniciar los valores
    setShowModal1(false);
    setisEditMode1(false);
  };
 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idactividadx = searchParams.get('idactividadx');
  const Swal = require('sweetalert2')
  


  
  const borrarActividad = (xidactividadx) => {
    const formdata = new FormData()
    console.log('La Actividad borrar es la :'+xidactividadx)
    formdata.append('id_actividadporcentaje', xidactividadx)
    fetch(_servidorapi+'eliminaractividadporcentaje', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => actividadBorrada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores



   }


  useEffect(() => {
    ObtenerActividad(idactividadx);
    const fetchData = async () => {
      try {
        console.log('Eñ contrato es :'+idactividadx)
        const url = _servidorapi+'maestroactividadporcentaje?id_actividad=' + encodeURIComponent(idactividadx);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setActividadpresupuesto(data);

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    
    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página

    return (
      <div className='main-container'>
        
        <h1 style={{ display: 'inline' }}>Actividad: {nombreactividad}</h1>
 
       
              <div className="card-header">
                <h2 className="h5 d-inline-block">Porcentajes x Vigencia</h2>
                <button onClick={handleActividadClick} className="btn btn-primary float-end">Adicionar %</button>
              </div>
              <div className="card-content">
              <table className="table table-dark table-striped table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th className="col-1">Vigencia</th>
                      <th className="col-5">Porcentaje</th>
                      <th className="col-3" ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {actividadpresupuesto.map((porcentaje, index) => (
                      <tr key={porcentaje.id_actividadporcentaje}>
                        <td >{porcentaje.vigencia}</td>
                        <td style={{ width: '20%', whiteSpace: 'nowrap' }}>{porcentaje.porcentaje}</td>
                        <td>
                        <div className="d-flex justify-content-center">
                            <div className="btn-group">
                                <button className="btn btn-danger" onClick={() => borrarActividad(porcentaje.id_actividadporcentaje)}>
                                Borrar
                                </button>
                                <button className="btn btn-success" onClick={() => handleEditClick(porcentaje.id_actividadporcentaje)}>
                                Editar
                                </button>
                            </div>
                            </div>
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

     
    
      </div>
      <Modal show={showModal1} onHide={() => setShowModal1(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode1 ? 'Editar Porcentaje' : 'Agregar Porcentaje'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="formVigencia">
              <Form.Label>Vigencia</Form.Label>
              <Form.Control
                type="number"
                placeholder="Vigencia"
                value={vigencia}
                onChange={e => setVigencia(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPresupuesto">
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                type="number"
                placeholder="Porcentaje"
                value={porcentaje}
                onChange={e => setPorcentaje(e.target.value)}
              />
            </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode1 ? handleSaveEditClick : handleSaveClick1}>{isEditMode1 ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
 

      </div>
    );
}

export default Actividadesporcentajes
