import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import classNames from 'classnames'
import { rankItem } from '@tanstack/match-sorter-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faChevronUp,faChevronRight,faAngleDoubleRight,faChevronLeft,faAngleDoubleLeft,faArrowUp,faArrowDown} from '@fortawesome/free-solid-svg-icons';

const _servidorapi = process.env.REACT_APP_URL_API

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)

  addMeta({ itemRank })

  return itemRank.passed
}

const DebouncedInput = ({ value: keyWord, onChange, ...props }) => {
  const [value, setValue] = useState(keyWord);
  // console.log(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log('Filterd');
      onChange(value);
    }, 500);
  
    return () => clearTimeout(timeout);
  }, [onChange, value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

const Proyectos = () => {
  const [isEditMode, setisEditMode] = useState(false);
  const [optionsDependencias, setOptionsDependencias] = useState([]);
  const [optionsResponsables, setOptionsResponsables] = useState([]);
  const Swal = require('sweetalert2')
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [presupuesto, setPresupuesto] = useState('');
  const [id_dependencia, setIddependencia] = useState('');
  const [id_responsable, setIdresponsable] = useState('');
  const [id_proyecto, setIdProyecto] = useState('');
  const [proyecto, setProyecto] = useState('');
  const [bpin, setBpin] = useState('');
  const [fecha_fin, setFin] = useState('');
  const [fecha_inicio, setInicio] = useState('');
  const [descripcion, setDescripcion] = useState('');
  //const [fecha, setFecha] = useState(new Date());
  const [fecha, setFecha] = useState('');

  const faltandatos = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Faltan datos por Llenar',
      text: 'Son Necesarios',
    }).then(async () => {
    });
  
    return null;
  };

  const proyectoCreado = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Proyecto Registrado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroproyectos');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };




  const ProyectoActualizado = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'success',
      title: 'Proyecto Actualizado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroproyectos');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };

  const handleAddClick = () => {
    setShowModal(true);
    setisEditMode(false);
  };

  const handleEditClick = async (idproyectox) => {
      setIdProyecto(idproyectox);
      const url = _servidorapi+'obtenerproyecto?id_proyecto=' + encodeURIComponent(idproyectox);
      const response = await fetch(url, { method: 'GET' });
      const data2 = await response.json();
      console.log(data2);
      setNombre(data2[0].nombre);
      setProyecto(data2[0].codigo);
      setBpin(data2[0].bpin);
      setPresupuesto(data2[0].presupuesto);
      setIdProyecto(data2[0].id_proyecto);
      setIdresponsable(data2[0].id_responsable);
      setIddependencia(data2[0].id_dependencia);
      setFecha(data2[0].fecha);
      setInicio(data2[0].fecha_desde);
      setFin(data2[0].fecha_hasta);
      setDescripcion(data2[0].descripcion);
      setisEditMode(true);
      setShowModal(true);
  };

  const navigate = useNavigate();


const handleObjetivosClick = async (idproyectox) => {
  console.log('No ENTRA');
  navigate(`/proyecto/proyectoobjetivos?idproyectox=${idproyectox}`);
  
};




  const handleSaveClick = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 || bpin.trim().length === 0 || presupuesto.trim().length === 0 || id_responsable.trim().length === 0 || 
        id_dependencia.trim().length === 0 || fecha.trim().length === 0 || fecha_inicio.trim().length === 0 || fecha_fin.trim().length === 0 ) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('nombre', nombre)
    formdata.append('codigo', proyecto)
    formdata.append('bpin', bpin)
    formdata.append('presupuesto', presupuesto)
    formdata.append('descripcion', descripcion)
    formdata.append('fecha', fecha)
    formdata.append('fecha_inicio', fecha_inicio)
    formdata.append('fecha_fin', fecha_fin)
    formdata.append('id_responsable', id_responsable)
    formdata.append('id_dependencia', id_dependencia)


    fetch(_servidorapi+'crearproyecto', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => proyectoCreado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setPresupuesto('');
        setFecha('');
        setDescripcion('');
        setIddependencia('');
        setInicio('');
        setFin('');
        setShowModal(false);
        setisEditMode(false);

  }
  
  const handleSaveEditClick = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 || bpin.trim().length === 0 || presupuesto.trim().length === 0 || id_responsable.trim().length === 0 || 
        id_dependencia.trim().length === 0 || fecha.trim().length === 0 || fecha_inicio.trim().length === 0 || fecha_fin.trim().length === 0 ) {
      faltandatos();
      return null
    } 
    const originalFecha = fecha;
    const originalDate = new Date(originalFecha);
    const formattedFecha = originalDate.toISOString().split('T')[0];

    const originalFechai = fecha_inicio;
    const originalDatei = new Date(originalFechai);
    const formattedFechai = originalDatei.toISOString().split('T')[0];

    const originalFechaf = fecha_fin;
    const originalDatef = new Date(originalFechaf);
    const formattedFechaf = originalDatef.toISOString().split('T')[0];
    const formdata = new FormData()
    formdata.append('id_proyecto', id_proyecto)
    formdata.append('nombre', nombre)
     formdata.append('codigo', proyecto)
    formdata.append('id_responsable', id_responsable)
    formdata.append('id_dependencia', id_dependencia)
    formdata.append('bpin', bpin)
    formdata.append('presupuesto', presupuesto)
    formdata.append('descripcion', descripcion)
    formdata.append('fecha', formattedFecha )
    formdata.append('fecha_inicio', formattedFechai)
    formdata.append('fecha_fin', formattedFechaf )

    fetch(_servidorapi+'editarproyecto', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ProyectoActualizado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setPresupuesto('');
        setFecha('');
        setDescripcion('');
        setIdresponsable('');
        setInicio('');
        setFin('');
        setShowModal(false);
        setisEditMode(false);

  }
  
  const MaestroEliminado = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Proyecto Borrado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroproyectos');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };
  

  const handleEliminarClick = (idProyectox) => {
    const formdata = new FormData()
    formdata.append('id_proyecto', idProyectox)

    fetch(_servidorapi+'eliminarproyecto', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => MaestroEliminado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores

  }


  const handleCancelClick = () => {
    // Reiniciar los valores
    setNombre('');
        setPresupuesto('');
        setFecha('');
        setDescripcion('');
        setIdresponsable('');
        setInicio('');
        setFin('');
    setShowModal(false);
    setisEditMode(false);
  };

  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  console.log(globalFilter);

  const columns = [
    {
      accessorKey: 'codigo',
      header: () => <span>Proyecto</span>,
      cell: info => (
        <div style={{ width: '150px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
      
    },
    {
      accessorKey: 'nombre',
      header: () => <span>Nom.Corto</span>,
      cell: info => (
        <div style={{ width: '200px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'fecha',
      header: () => <span>Fecha</span>,
      cell: info => {
        const fecha = new Date(info.getValue());
        const fechaFormateada = fecha.toLocaleDateString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
    
        return (
          <div style={{ width: '90px', textAlign: 'left' }}>
            <span className='font-bold'>{fechaFormateada}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'bpin',
      header: () => <span>BPIN</span>,
      cell: info => (
        <div style={{ width: '200px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'presupuesto',
      header: () => <span>Presupuesto</span>,
      cell: info => (
        <div style={{ width: '150px', textAlign: 'right' }}>
  <span className='font-bold'>
    {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(info.getValue())}
  </span>
</div>
      ),
    },
    {
      accessorKey: 'id_proyecto',
      header: 'Acciones',
      cell: info => {
        const XSetidProyecto = info.getValue('id_proyecto');
        return (
          <div className='d-flex align-items-center'>
          <button className='btn btn-primary' onClick={() => handleEditClick(XSetidProyecto)}>Edit.</button>
          <button className='btn btn-danger' onClick={() => handleEliminarClick(XSetidProyecto)}>Elim.</button>
          <button className='btn btn-info' onClick={() => handleObjetivosClick(XSetidProyecto)}>Obj.</button>
        </div>
        )
      },
      enableSorting: false
    }
  ]

  const getStateTable = () => {
    const totalRows = table.getFilteredRowModel().rows.length;
    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;
    const rowsPerPage = table.getRowModel().rows.length;

    const firstIndex = (pageIndex * pageSize) + 1;
    const lastIndex = (pageIndex * pageSize) + rowsPerPage;

    return {
      totalRows,
      firstIndex,
      lastIndex
    }
  }
  
   
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting
    },
    initialState: {
      pagination: {
        pageSize: 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi+'maestroproyectos');
        const jsonData = await response.json();
        setData(jsonData);
        const responseZona = await fetch(_servidorapi+'maestrodependencias');
        const jsonFidelizado = await responseZona.json();
        const jsonOptionContratista =  [
          { value: "", label: "Seleccionar la Dependencia" },
          ...jsonFidelizado.map(item => ({ value: item.id_dependencia, label: item.nombre })),
        ];
        setOptionsDependencias(jsonOptionContratista);

        const responseResponsable = await fetch(_servidorapi+'maestroresponsables');
        const jsonResponsable = await responseResponsable.json();
        const jsonOptionResponsable =  [
          { value: "", label: "Seleccionar el Responsable" },
          ...jsonResponsable.map(item => ({ value: item.id_responsable, label: item.nombre })),
        ];
        setOptionsResponsables(jsonOptionResponsable);
       
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página


  return (
    <div className="container">
    <div className='px-6 py-4'>
       <Button onClick={handleAddClick} variant="primary">Nuevo Proyecto</Button>
      <div className='my-2 d-flex justify-content-end'>
      <div className="position-relative">
  <div className="input-group">
    <DebouncedInput
      type="text"
      value={globalFilter ?? ''}
      onChange={value => setGlobalFilter(String(value))}
      className="form-control"
      placeholder="Buscar..."
    />
    <button className="btn btn-primary" type="button">
      <FontAwesomeIcon icon={faSearch} className="text-light" />
    </button>
  </div>
</div>
      </div> 
      <div className='overflow-auto'>
          <table className='table table-striped w-full min-w-[560px]'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="border-bottom border-gray-300 text-gray-600 bg-gray-100">
                  {headerGroup.headers.map(header => (
                    <th key={header.id} className="py-2 px-4 text-left text-uppercase">
                      {header.isPlaceholder
                        ? null
                        :
                        <div
                          className={classNames({
                            'cursor-pointer select-none d-flex justify-content-between': header.column.getCanSort(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FontAwesomeIcon icon={faArrowUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />,
                            desc: <FontAwesomeIcon icon={faArrowDown} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          }[header.column.getIsSorted()] ?? (header.column.getCanSort() ? <FontAwesomeIcon icon={faChevronUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          : null)}
                        </div>
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="text-gray-600 hover:bg-slate-100">
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="py-2 px-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-between space-y-4 text-center'>
          <div className='d-flex align-items-center gap-2'>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faChevronLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
      
            {table.getPageOptions().map((value, key) => (
              <button key={key}
                className={classNames({
                  "btn btn-light rounded border border-gray-300 font-weight-bold disabled:hover:bg-white disabled:hover:text-gray-300": true,
                  "bg-indigo-200 text-indigo-700": value === table.getState().pagination.pageIndex
                })}
                onClick={() => table.setPageIndex(value)}>
                {value + 1}
              </button>
            ))}
      
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}>
            <FontAwesomeIcon icon={faChevronRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}>
              <FontAwesomeIcon icon={faAngleDoubleRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
          </div>
          <div className='text-gray-600 font-weight-bold'>
            {getStateTable().firstIndex}&nbsp;
            a {getStateTable().lastIndex}&nbsp;
            de {getStateTable().totalRows} 
          </div>
          <div className="d-inline-block">
            <select
              className="form-select text-muted border border-muted rounded-pill outline-primary py-2"
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}>
            <option value="5">5 pág.</option>
            <option value="10">10 pág.</option>
            <option value="20">20 pág.</option>
            <option value="25">25 pág.</option>
            <option value="50">50 pág.</option>
          </select>
          </div>
     </div>
     <Modal show={showModal} onHide={() => setShowModal(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode ? 'Editar Proyecto' : 'Agregar Proyecto'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Proyecto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Proyecto"
                value={proyecto}
                onChange={e => setProyecto(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formLugar">
              <Form.Label>Nombre Corto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre Corto"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBpin">
              <Form.Label>BPIN</Form.Label>
              <Form.Control
                type="text"
                placeholder="Codigo BPIN"
                value={bpin}
                onChange={e => setBpin(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formValor">
              <Form.Label>Presupuesto</Form.Label>
              <Form.Control
                type="number"
                placeholder="Valor Proyecto"
                value={presupuesto}
                onChange={e => setPresupuesto(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFecha">
  <Form.Label>Fecha</Form.Label>
  <Form.Control
    type="date"
    placeholder="fecha"
    value={fecha ? fecha.slice(0, 10) : ''}
    onChange={e => setFecha(e.target.value)}
  />
</Form.Group>

<Form.Group controlId="formFechadesde">
  <Form.Label>Fecha Inicio</Form.Label>
  <Form.Control
    type="date"
    placeholder="Fecha incio del contrato"
    value={fecha_inicio ? fecha_inicio.slice(0, 10) : ''}
    onChange={e => setInicio(e.target.value)}
  />
</Form.Group>
<Form.Group controlId="formFecha">
  <Form.Label>Fecha Fin </Form.Label>
  <Form.Control
    type="date"
    placeholder="Fecha Termina."
    value={fecha_fin ? fecha_fin.slice(0, 10) : ''}
    onChange={e => setFin(e.target.value)}
  />
</Form.Group>
            <Form.Group controlId="formDescripcion">
            <Form.Label>Objeto</Form.Label>
            <Form.Control
              as="textarea" 
              rows={3} 
              placeholder="Objeto General"
              value={descripcion} 
              onChange={e => setDescripcion(e.target.value)} 
            />
          </Form.Group>
            <Form.Group controlId="formUserType">
              <Form.Label>Dependencia</Form.Label>
              <Form.Control
                as="select"
                value={id_dependencia}
                onChange={e => setIddependencia(e.target.value)}
              >
                {optionsDependencias.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formUserType2">
              <Form.Label>Responsable</Form.Label>
              <Form.Control
                as="select"
                value={id_responsable}
                onChange={e => setIdresponsable(e.target.value)}
              >
                {optionsResponsables.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode ? handleSaveEditClick : handleSaveClick}>{isEditMode ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
    </div>
    </div>
  )
}

export default Proyectos