import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import classNames from 'classnames'
import { rankItem } from '@tanstack/match-sorter-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faChevronUp,faChevronRight,faAngleDoubleRight,faChevronLeft,faAngleDoubleLeft,faArrowUp,faArrowDown} from '@fortawesome/free-solid-svg-icons';

const _servidorapi = process.env.REACT_APP_URL_API

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)

  addMeta({ itemRank })

  return itemRank.passed
}

const DebouncedInput = ({ value: keyWord, onChange, ...props }) => {
  const [value, setValue] = useState(keyWord);
  // console.log(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log('Filterd');
      onChange(value);
    }, 500);
  
    return () => clearTimeout(timeout);
  }, [onChange, value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

const Contratos = () => {
  const [isEditMode, setisEditMode] = useState(false);
  const [optionsContratistas, setOptionsContratistas] = useState([]);
  const Swal = require('sweetalert2')
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [valor, setValor] = useState('');
  const [id_contratista, setIdcontratista] = useState('');
  const [id_contrato, setIdContrato] = useState('');
  const [contrato, setContrato] = useState('');
  const [fecha_fin, setFin] = useState('');
  const [fecha_inicio, setInicio] = useState('');
  const [descripcion, setDescripcion] = useState('');
  //const [fecha, setFecha] = useState(new Date());
  const [fecha, setFecha] = useState('');

  const faltandatos = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Faltan datos por Llenar',
      text: 'Son Necesarios',
    }).then(async () => {
    });
  
    return null;
  };

  const contratoCreado = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Contrato Registrado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestrocontratos');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };




  const ContratoActualizado = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'success',
      title: 'Contrato Actualizado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestrocontratos');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };


  const ContratoEliminado = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'success',
      title: 'Contrato Eliminado',
      text: '',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestrocontratos');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };
  const handleAddClick = () => {
    setNombre('');
    setContrato('');
        setValor('');
        setFecha('');
        setDescripcion('');
        setIdcontratista('');
        setInicio('');
    setShowModal(true);
    setisEditMode(false);
  };

  const handleEditClick = async (idcontratox) => {
      setIdContrato(idcontratox);
      const url = _servidorapi+'obtenercontrato/?id_contrato=' + encodeURIComponent(idcontratox);
      const response = await fetch(url, { method: 'GET' });
      const data2 = await response.json();
      console.log(data2);
      setNombre(data2[0].nombre);
      setContrato(data2[0].codigo);
      setValor(data2[0].valor);
      setIdContrato(data2[0].id_contrato);
      setIdcontratista(data2[0].id_contratista);
      setFecha(data2[0].fecha_contrato);
      setInicio(data2[0].fecha_inicio);
      setFin(data2[0].fecha_fin);
      setDescripcion(data2[0].objeto);
      setisEditMode(true);
      setShowModal(true);
  };

  const navigate = useNavigate();
  const handleActividadClick = async (idcontratox) => {
    console.log('No ENTRA');
    navigate(`/contrato/contratoactividad?idcontratox=${idcontratox}`);
    
};
const handlePresupuestoClick = async (idcontratox) => {
  console.log('No ENTRA');
  navigate(`/contrato/contratopresupuesto?idcontratox=${idcontratox}`);
  
};



  const handleSaveClick = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 || contrato.trim().length === 0 || valor.trim().length === 0 || id_contratista.trim().length === 0 || 
        fecha.trim().length === 0 || fecha_inicio.trim().length === 0 || fecha_fin.trim().length === 0 ) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('nombre', nombre)
    formdata.append('contrato', contrato)
    formdata.append('valor', valor)
    formdata.append('descripcion', descripcion)
    formdata.append('fecha', fecha)
    formdata.append('fecha_inicio', fecha_inicio)
    formdata.append('fecha_fin', fecha_fin)
    formdata.append('id_contratista', id_contratista)


    fetch(_servidorapi+'crearcontrato', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => contratoCreado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setValor('');
        setFecha('');
        setDescripcion('');
        setIdcontratista('');
        setInicio('');
        setFin('');
        setShowModal(false);
        setisEditMode(false);

  }
  
  const handleSaveEditClick = () => {
    if (
      (nombre && nombre.trim().length === 0) ||
      (descripcion && descripcion.trim().length === 0) ||
      valor <= 0 ||
      id_contratista <= 0 ||
      (fecha && fecha.trim().length === 0) ||
      (fecha_inicio && fecha_inicio.trim().length === 0) ||
      (fecha_fin && fecha_fin.trim().length === 0)
    ) {
      faltandatos();
      return null;
    }
    const originalFecha = fecha;
    const originalDate = new Date(originalFecha);
    const formattedFecha = originalDate.toISOString().split('T')[0];

    const originalFechai = fecha_inicio;
    const originalDatei = new Date(originalFechai);
    const formattedFechai = originalDatei.toISOString().split('T')[0];

    const originalFechaf = fecha_fin;
    const originalDatef = new Date(originalFechaf);
    const formattedFechaf = originalDatef.toISOString().split('T')[0];

    const formdata = new FormData()
    formdata.append('id_contrato', id_contrato)
    formdata.append('nombre', nombre)
    formdata.append('codigo', contrato)
    formdata.append('id_contratista', id_contratista)
    formdata.append('valor', valor)
    formdata.append('descripcion', descripcion)
    formdata.append('fecha', formattedFecha)
    formdata.append('fecha_inicio', formattedFechai)
    formdata.append('fin', formattedFechaf )

    fetch(_servidorapi+'editarcontrato', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ContratoActualizado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setValor('');
        setFecha('');
        setDescripcion('');
        setIdcontratista('');
        setInicio('');
        setFin('');
        setShowModal(false);
        setisEditMode(false);

  }
  
  
  const handleEliminarClick = (idContratox) => {
    const formdata = new FormData()
    formdata.append('id_contrato', idContratox)

    fetch(_servidorapi+'eliminarcontrato', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ContratoEliminado ())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setValor('');
        setFecha('');
        setDescripcion('');
        setIdcontratista('');
        setInicio('');
        setFin('');
        setShowModal(false);
        setisEditMode(false);

  }

  const handleCancelClick = () => {
    // Reiniciar los valores
    setNombre('');
        setValor('');
        setFecha('');
        setDescripcion('');
        setIdcontratista('');
        setInicio('');
        setFin('');
    setShowModal(false);
    setisEditMode(false);
  };

  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  console.log(globalFilter);

  const columns = [
    {
      accessorKey: 'codigo',
      header: () => <span>Contrato</span>,
      cell: info => <span className='font-bold'>{info.getValue()}</span>
    },
    {
      accessorKey: 'nombre',
      header: () => <span>Nom.Corto</span>,
      cell: info => (
        <div style={{ width: '200px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'fecha_contrato',
      header: () => <span>Fecha</span>,
      cell: info => {
        const fecha_contrato = new Date(info.getValue());
        const fechacontratoFormateada = fecha_contrato.toLocaleDateString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
    
        return (
          <div style={{ width: '90px', textAlign: 'left' }}>
            <span className='font-bold'>{fechacontratoFormateada}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'fecha_inicio',
      header: () => <span>Fecha Inicio</span>,
      cell: info => {
        const fecha_inicio = new Date(info.getValue());
        const fechainicioFormateada = fecha_inicio.toLocaleDateString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
    
        return (
          <div style={{ width: '90px', textAlign: 'left' }}>
            <span className='font-bold'>{fechainicioFormateada}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'fecha_fin',
      header: () => <span>Fecha Fin </span>,
      cell: info => {
        const fecha_fin = new Date(info.getValue());
        const fechafinFormateada = fecha_fin.toLocaleDateString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
    
        return (
          <div style={{ width: '90px', textAlign: 'left' }}>
            <span className='font-bold'>{fechafinFormateada}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'valor',
      header: () => <span>Valor</span>,
      cell: info => (
        <div style={{ width: '150px', textAlign: 'right' }}>
  <span className='font-bold'>
    {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(info.getValue())}
  </span>
</div>
      ),
    },
    {
      accessorKey: 'id_contrato',
      header: 'Acciones',
      cell: info => {
        const XSetidContrato = info.getValue('id_contrato');
        return (
          <div className='d-flex align-items-center'>
          <button className='btn btn-primary' onClick={() => handleEditClick(XSetidContrato)}>Edit.</button>
          <button className='btn btn-danger' onClick={() => handleEliminarClick(XSetidContrato)}>Elim.</button>
          <button className='btn btn-warning' onClick={() => handleActividadClick(XSetidContrato)}>Activ.</button>
        </div>
        )
      },
      enableSorting: false
    }
  ]

  const getStateTable = () => {
    const totalRows = table.getFilteredRowModel().rows.length;
    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;
    const rowsPerPage = table.getRowModel().rows.length;

    const firstIndex = (pageIndex * pageSize) + 1;
    const lastIndex = (pageIndex * pageSize) + rowsPerPage;

    return {
      totalRows,
      firstIndex,
      lastIndex
    }
  }
  
   
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting
    },
    initialState: {
      pagination: {
        pageSize: 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi+'maestrocontratos');
        const jsonData = await response.json();
        setData(jsonData);
        const responseZona = await fetch(_servidorapi+'maestrocontratistas');
        const jsonFidelizado = await responseZona.json();
        const jsonOptionContratista =  [
          { value: "", label: "Seleccionar el Contratista" },
          ...jsonFidelizado.map(item => ({ value: item.id_contratista, label: item.nombre })),
        ];
        setOptionsContratistas(jsonOptionContratista);
       
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página


  return (
    <div className="container">
    <div className='px-6 py-4'>
       <Button onClick={handleAddClick} variant="primary">Nuevo Contrato</Button>
      <div className='my-2 d-flex justify-content-end'>
      <div className="position-relative">
  <div className="input-group">
    <DebouncedInput
      type="text"
      value={globalFilter ?? ''}
      onChange={value => setGlobalFilter(String(value))}
      className="form-control"
      placeholder="Buscar..."
    />
    <button className="btn btn-primary" type="button">
      <FontAwesomeIcon icon={faSearch} className="text-light" />
    </button>
  </div>
</div>
      </div> 
      <div className='overflow-auto'>
          <table className='table table-striped w-full min-w-[560px]'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="border-bottom border-gray-300 text-gray-600 bg-gray-100">
                  {headerGroup.headers.map(header => (
                    <th key={header.id} className="py-2 px-4 text-left text-uppercase">
                      {header.isPlaceholder
                        ? null
                        :
                        <div
                          className={classNames({
                            'cursor-pointer select-none d-flex justify-content-between': header.column.getCanSort(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FontAwesomeIcon icon={faArrowUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />,
                            desc: <FontAwesomeIcon icon={faArrowDown} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          }[header.column.getIsSorted()] ?? (header.column.getCanSort() ? <FontAwesomeIcon icon={faChevronUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          : null)}
                        </div>
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="text-gray-600 hover:bg-slate-100">
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="py-2 px-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-between space-y-4 text-center'>
          <div className='d-flex align-items-center gap-2'>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faChevronLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
      
            {table.getPageOptions().map((value, key) => (
              <button key={key}
                className={classNames({
                  "btn btn-light rounded border border-gray-300 font-weight-bold disabled:hover:bg-white disabled:hover:text-gray-300": true,
                  "bg-indigo-200 text-indigo-700": value === table.getState().pagination.pageIndex
                })}
                onClick={() => table.setPageIndex(value)}>
                {value + 1}
              </button>
            ))}
      
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}>
            <FontAwesomeIcon icon={faChevronRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}>
              <FontAwesomeIcon icon={faAngleDoubleRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
          </div>
          <div className='text-gray-600 font-weight-bold'>
            {getStateTable().firstIndex}&nbsp;
            a {getStateTable().lastIndex}&nbsp;
            de {getStateTable().totalRows} 
          </div>
          <div className="d-inline-block">
            <select
              className="form-select text-muted border border-muted rounded-pill outline-primary py-2"
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}>
            <option value="5">5 pág.</option>
            <option value="10">10 pág.</option>
            <option value="20">20 pág.</option>
            <option value="25">25 pág.</option>
            <option value="50">50 pág.</option>
          </select>
          </div>
     </div>
     <Modal show={showModal} onHide={() => setShowModal(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode ? 'Editar Contrato' : 'Agregar Contrato'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Contrato</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contrato"
                value={contrato}
                onChange={e => setContrato(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formLugar">
              <Form.Label>Nombre Corto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre Corto"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formValor">
              <Form.Label>Valor</Form.Label>
              <Form.Control
                type="number"
                placeholder="Valor Contrato"
                value={valor}
                onChange={e => setValor(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formFecha">
  <Form.Label>Fecha</Form.Label>
  <Form.Control
    type="date"
    placeholder="fecha"
    value={fecha ? fecha.slice(0, 10) : ''}
    onChange={e => setFecha(e.target.value)}
  />
</Form.Group>

<Form.Group controlId="formFechadesde">
  <Form.Label>Fecha Inicio</Form.Label>
  <Form.Control
    type="date"
    placeholder="Fecha incio del contrato"
    value={fecha_inicio ? fecha_inicio.slice(0, 10) : ''}
    onChange={e => setInicio(e.target.value)}
  />
</Form.Group>
<Form.Group controlId="formFecha">
  <Form.Label>Fecha Fin </Form.Label>
  <Form.Control
    type="date"
    placeholder="Fecha Termina."
    value={fecha_fin ? fecha_fin.slice(0, 10) : ''}
    onChange={e => setFin(e.target.value)}
  />
</Form.Group>
            <Form.Group controlId="formDescripcion">
            <Form.Label>Objeto</Form.Label>
            <Form.Control
              as="textarea" 
              rows={3} 
              placeholder="Objeto General"
              value={descripcion} 
              onChange={e => setDescripcion(e.target.value)} 
            />
          </Form.Group>
            <Form.Group controlId="formUserType">
              <Form.Label>Contratista</Form.Label>
              <Form.Control
                as="select"
                value={id_contratista}
                onChange={e => setIdcontratista(e.target.value)}
              >
                {optionsContratistas.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode ? handleSaveEditClick : handleSaveClick}>{isEditMode ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
    </div>
    </div>
  )
}

export default Contratos