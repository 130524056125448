import React from 'react'
import {BsFillFileTextFill} from 'react-icons/bs'
import { Link } from 'react-router-dom'; 

const ComContratos = () => {
  return (
    <div><div className='card' style={{ backgroundColor: 'green', color: 'white' }}>
    <Link to="/comcontratosdetalle" className="card-link">
        <div className='card-inner'>
          <h3 style={{ color: 'white' }}>Contratos</h3>
          <BsFillFileTextFill className='card-iicon'/>
        </div>
        <h1>2</h1>
      </Link>
    </div></div>
  )
}

export default ComContratos