import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';
import "./Maestros.css";
import "../App.css";
const optionscontrato =[{ value: 1, label: 'Contrataccion' },{ value: 2, label: 'Personal Propio' }];
const _servidorapi = process.env.REACT_APP_URL_API
const Proyectoactividades = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [isEditMode1, setisEditMode1] = useState(false);
  const [actividadProyecto, setaactividadproyecto] = useState([]);
  const [optionsProyecto, setObjetivos] = useState([]);
  const [optionsObjetivosp, setObjetivosp] = useState([]);
  const [nombre, setNombre] = useState('');
  const [valor, setValor] = useState('');
  const [id_actividadproyecto, setIdActividadProyecto] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [porcentaje, setPorcentaje] = useState(1);
  const [actividad, setActividad] = useState('');
  const [id_actividad, setIdactividad] = useState('');
  const [id_objetivope, setObjetivope] = useState('');
  const [id_proyectoobjetivo, setIdobjetivo] = useState('');
  const [optionsActividad, setOptionsActividad] = useState([]);
  const [contrato, setContrato] = useState(0);
  const [nombreproyecto, setNombreProyecto] = useState('');

  const obtenerproyecto = async (idaproyectox) => {
    const url = _servidorapi+'obtenerproyectoobjetivo/?id_proyectoobjetivo=' + encodeURIComponent(idproyectox);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setNombreProyecto(data2[0].nombre);
};
  
  const actividadCreadaProyecto = async () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          icon: 'success',
          title: 'Actividad Registrada',
          text: 'Gracias',
        }).then(async () => {

          const url = _servidorapi+'obtenerproyectoactividades?id_proyectoobjetivo=' + encodeURIComponent(id_proyectoobjetivox);
          const response = await fetch(url, { method: 'GET' });
          const data = await response.json();
          setaactividadproyecto(data);
        
        });
      
        return null;
  };

  const ActividadActualizada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Actividad Actualizada',
        text: 'Gracias',
      }).then(async () => {

        const url = _servidorapi+'obtenerproyectoactividades?id_proyectoobjetivo=' + encodeURIComponent(id_proyectoobjetivox);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setaactividadproyecto(data);
      });
    
      return null;
  };


  const actividadBorrada = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Actividad Borrada',
      text: 'Gracias',
    }).then(async () => {
      const url = _servidorapi+'obtenerproyectoactividades?id_proyectoobjetivo=' + encodeURIComponent(id_proyectoobjetivox);
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();
        setaactividadproyecto(data);
    });
  
    return null;
  };
  

  const handleSaveClick1 = () => {
      console.log('Contrato:'+contrato)
      const formdata = new FormData()
      formdata.append('nombre', nombre)
      formdata.append('presupuesto', valor)
      formdata.append('porcentaje', porcentaje)
      formdata.append('cantidad', cantidad)
      formdata.append('descripcion', descripcion)
      formdata.append('id_actividad', id_actividad)
      formdata.append('id_proyectoobjetivo', id_proyectoobjetivox)
      formdata.append('id_objetivope', id_objetivope)
      formdata.append('contrato', contrato)
      fetch(_servidorapi+'crearactividadproyecto', {
        method: 'POST',
        body: formdata
      })
      .then(res => res.text())
      .then(res => actividadCreadaProyecto())
      .catch(err => {
        console.error('EL Error es:'+err)
      })
          // Reiniciar los valores
          setNombre('');
          setValor('');
          setActividad('');
          setPorcentaje('');
          setObjetivope('')
          setContrato('');
          setShowModal1(false);
          setisEditMode1(false);
          fetchActividadProyecto(idproyectox);
  }

  const handleSaveEditClick = () => {
    const formdata = new FormData()
    formdata.append('id_actividadproyecto', id_actividadproyecto)
    formdata.append('nombre', nombre)
    formdata.append('descripcion', descripcion)
    formdata.append('presupuesto', valor)
    formdata.append('porcentaje', porcentaje)
    formdata.append('cantidad', cantidad)
    formdata.append('contrato', contrato)
    formdata.append('id_actividad', id_actividad)
    formdata.append('id_objetivope', id_objetivope)
    fetch(_servidorapi+'editarproyectoactividad', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ActividadActualizada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setShowModal1(false);
        setisEditMode1(false);

  }
  const handleActividadClick = () => {
    setShowModal1(true);
    setisEditMode1(false);
  };


  const handleEditClick = async (idActividadx) => {
    setIdactividad(idActividadx)
    setIdActividadProyecto(idActividadx)
    const url = _servidorapi+'obtenerproyectoactividad/?id_actividadproyecto=' + encodeURIComponent(idActividadx);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setNombre(data2[0].nombre);
    setDescripcion(data2[0].descripcion);
    setCantidad(data2[0].cantidad)
    setValor(data2[0].presupuesto)
    setPorcentaje(data2[0].porcentaje)
    setContrato(data2[0].contrato)
    setIdactividad(data2[0].id_actividad)
    setIdobjetivo(data2[0].id_proyectoobjetivo)
    setObjetivope(data2[0].id_objetivope)
    setShowModal1(true);
    setisEditMode1(true);
};

  const handleCancelClick = () => {
    // Reiniciar los valores
    setNombre('');
    setShowModal1(false);
    setisEditMode1(false);
  };
 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idproyectox = searchParams.get('idproyectox');
  const id_proyectoobjetivox = searchParams.get('idproyectox');
  const Swal = require('sweetalert2')

  async function fetchActividadProyecto(idproyectox) {
    try {
      const response = await fetch(_servidorapi+'obtenerproyectoactividades/get?id_proyecto=${idproyectox}', {
        method: 'GET'
      });
  
      if (!response.ok) {
        throw new Error('Error en la solicitud de la API');
      }
  
      const data = await response.json();
      setaactividadproyecto(data);
    } catch (error) {
      console.error(error);
    }
  }
  
  const navigate = useNavigate();

  const handlePresupuestoClick = async (idactividadx) => {
    console.log('No ENTRA');
    navigate(`/proyecto/proyectoactividad/proyectoactividadpresupuesto?idactividadx=${idactividadx}`);
    
};

  
  const borrarActividad = (xidactividadx) => {
    const formdata = new FormData()
    console.log('La ACtividaca borrar es la :'+xidactividadx)
    formdata.append('id_actividadproyecto', xidactividadx)
    fetch(_servidorapi+'eliminaractividadproyecto', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => actividadBorrada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        const fetchData = async () => {
          try {
            const responseActividad = await fetch(_servidorapi+'maestroactividadesplan');
            const jsonActividad = await responseActividad.json();
            const jsonOptionActividad =  [
              { value: "", label: "Seleccione la Actividad del Plan" },
              ...jsonActividad.map(item => ({ value: item.id_actividad, label: item.nombre })),
            ];
            setOptionsActividad(jsonOptionActividad);
            
            const responseObjetivos = await fetch(_servidorapi+'maestroobjetivospe');
            const response3 = await fetch(responseObjetivos, { method: 'GET' });
            const jsonObjetivos = await response3.json();
            const optionsObjetivospe =  [
              { value: "", label: "Objetivo del Plan Estrategico" },
              ...jsonObjetivos.map(item => ({ value: item.id_objetivope, label: item.nombre })),
            ];
            setObjetivosp(optionsObjetivospe)

            const urlp = _servidorapi+'obtenerproyectoactividades?id_proyecto=' + encodeURIComponent(idproyectox);
            const responsep = await fetch(urlp, { method: 'GET' });
            const datap = await responsep.json();
            const optionsProyectos =  [
              { value: "", label: "Objetivo del Plan Estrategico" },
              ...datap.map(item => ({ value: item.id_proyectoobjetivo, label: item.nombre })),
            ];
            setObjetivos(optionsProyectos);

            const url = _servidorapi+'obtenerproyectoactividades?id_proyecto=' + encodeURIComponent(idproyectox);
            const response = await fetch(url, { method: 'GET' });
            const data = await response.json();
            setaactividadproyecto(data);
    
          } catch (error) {
            console.error('Error al cargar los datos:', error);
          }
        };
        
        fetchData();


   }


  async function consultaparaeliminar(idactividadx) {
    try {
      const datos = await obtenerDatos(idactividadx);
      console.log(datos)
      if (datos.length > 0) {
        noeliminar();
      } else {
        borrarActividad(idactividadx)
      }
    } catch (error) {
      // Maneja cualquier error que pueda ocurrir durante la consulta o eliminación
      console.error('Error:', error);
    }
  }

async function obtenerDatos(idactividadx) {
    const url = _servidorapi+'consultaactividadespnsvproyectos/?id_actividadproyecto=' + encodeURIComponent(idactividadx);
    const response = await fetch(url, { method: 'GET' });
    const datae = await response.json();
   return datae; 
}
  const noeliminar = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Actividad enlazada',
      text: 'No se puede eliminar',
    }).then(async () => {
    });
  
    return null;
  };


  useEffect(() => {
    obtenerproyecto(idproyectox)
    const fetchData = async () => {
      try {
        const responseActividad = await fetch(_servidorapi+'maestroactividadesplan');
        const jsonActividad = await responseActividad.json();
        const jsonOptionActividad =  [
          { value: "", label: "Actividad del PNSV" },
          ...jsonActividad.map(item => ({ value: item.id_actividad, label: item.nombre })),
        ];
        setOptionsActividad(jsonOptionActividad)

        const responseObjetivosp = await fetch(_servidorapi+'maestroobjetivospe');
        const jsonObjetivosp = await responseObjetivosp.json();
        const jsonOptionObjetivosp =  [
          { value: "", label: "Objetivo Plan Estrategico" },
          ...jsonObjetivosp.map(item => ({ value: item.id_objetivope, label: item.nombre })),
        ];
        setObjetivosp(jsonOptionObjetivosp)

        const urlx = _servidorapi+'obtenerproyectosobjetivos?id_proyecto=' + encodeURIComponent(idproyectox);
        const responsex = await fetch(urlx, { method: 'GET' });
        const datax = await responsex.json();
        const jsonOptionProyecto =  [
          { value: "", label: "Objetivo del Proyecto" },
          ...datax.map(item => ({ value: item.id_proyectoobjetivo, label: item.nombre })),
        ];
        setObjetivos(jsonOptionProyecto);


        const url = _servidorapi+'obtenerproyectoactividades?id_proyectoobjetivo=' + encodeURIComponent(id_proyectoobjetivox);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setaactividadproyecto(data);

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    
    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página

    return (
      <div className='main-container'>
        <h1 style={{ display: 'inline' }}>Objetivo Proyecto: {nombreproyecto}</h1>
       
  
              <div className="card-header">
                <button onClick={handleActividadClick} className="btn btn-primary float-end">Adicionar Actividad</button>
              </div>
              <div className="card-content">
              <table className="table table-dark table-striped table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th className="col-2">Actividad</th>
                      <th className="col-3">Descripcion</th>
                      <th className="col-1">PNSV</th>
                      <th className="col-1">Plan Est.</th>
                      <th className="col-1">Obj.Proy.</th>
                      <th className="col-2">Presupuesto</th>
                      <th className="col-3">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actividadProyecto.map((asistencia, index) => (
                      <tr key={asistencia.id_actividadproyecto}>
                        <td>{asistencia.nombre}</td>
                        <td>{asistencia.descripcion}</td>
                        <td>{asistencia.actividad}</td>
                        <td>{asistencia.plan}</td>
                        <td>{asistencia.proyecto}</td>
                        <td style={{ textAlign: 'right' }}>{asistencia.valor.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>


                        <td>
                        <button className="btn btn-danger" onClick={() => consultaparaeliminar(asistencia.id_actividadproyecto)}>
                          Borrar
                        </button>
                        <button className="btn btn-success" onClick={() => handleEditClick(asistencia.id_actividadproyecto)}>
                          Editar
                        </button>

                        <button className='btn btn-warning' onClick={() => handlePresupuestoClick(asistencia.id_actividadproyecto)}>Presupuesto</button>
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
    
    
      </div>
      <Modal show={showModal1} onHide={() => setShowModal1(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode1 ? 'Editar Actividad' : 'Agregar Actividad'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formDescripcion">
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              as="textarea" 
              rows={3} 
              placeholder="Objeto General"
              value={descripcion} 
              onChange={e => setDescripcion(e.target.value)} 
            />
          </Form.Group>
            <Form.Group controlId="formUserType">
              <Form.Label>Objetivo Plan Estrategico</Form.Label>
              <Form.Control
                as="select"
                value={id_objetivope}
                onChange={e => setObjetivope(e.target.value)}
              >
                {optionsObjetivosp.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formUserType">
              <Form.Label>Actividad PNSV</Form.Label>
              <Form.Control
                as="select"
                value={id_actividad}
                onChange={e => setIdactividad(e.target.value)}
              >
                {optionsActividad.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formPresupuesto">
              <Form.Label>Presupuesto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Valor Presupuestado de la actividad"
                value={valor}
                onChange={e => setValor(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCelular">
              <Form.Label>Cantidad</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cant. segun objetivo a ejecutar"
                value={cantidad}
                onChange={e => setCantidad(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCedula">
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                type="text"
                placeholder="Porc. dentro del Objetivo"
                value={porcentaje}
                onChange={e => setPorcentaje(e.target.value)}
              />
            </Form.Group>  
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode1 ? handleSaveEditClick : handleSaveClick1}>{isEditMode1 ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
 

      </div>
    );
}

export default Proyectoactividades

