import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';
import "./Maestros.css";
import "../App.css";
const _servidorapi = process.env.REACT_APP_URL_API

const Planobjetivos = () => {
  const Swal = require('sweetalert2')
  const [showModal1, setShowModal1] = useState(false);
  const [isEditMode1, setisEditMode1] = useState(false);
  const [optionsUnidad, setOptionsUnidad] = useState([]);
  const [optionsObjetivos, setObjetivos] = useState([]);
  const [id_unidad, setUnidad] = useState('');
  const [nombre, setNombre] = useState('');
  const [valor, setValor] = useState('');
  const [id_planobjetivo, setObjetivo] = useState('');
  const [descripcion, setDescripcion] = useState('');


  const navigate = useNavigate();

  const handlePresupuestoClick = async (idactividadx) => {
    console.log('No ENTRA');
    navigate(`/planactividades?id_planobjetivo=${idactividadx}`);
    
  };

  
  const faltandatos = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Faltan datos por Llenar',
      text: 'Son Necesarios',
    }).then(async () => {
    });
  
    return null;
  };

  
  const actividadCreadaProyecto = async () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          icon: 'success',
          title: 'Objetivo Registrado',
          text: 'Gracias',
        }).then(async () => {

          const response = await fetch(_servidorapi+'maestrosplanobjetivos');
          const jsonData = await response.json();
          setObjetivos(jsonData);
        
        });
      
        return null;
  };

  const ActividadActualizada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Objetivo Actualizado',
        text: 'Gracias',
      }).then(async () => {

        const response = await fetch(_servidorapi+'maestrosplanobjetivos');
        const jsonData = await response.json();
        setObjetivos(jsonData);
      });
    
      return null;
  };

  const actividadBorrada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Objetivo Borrado',
        text: 'OK',   
      }).then(async () => {

        const response = await fetch(_servidorapi+'maestrosplanobjetivos');
        const jsonData = await response.json();
        setObjetivos(jsonData);
      
      });
    
      return null;
};

  const handleSaveClick1 = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 ||id_unidad <= 0  || valor <= 0 ) {
      faltandatos();
      return null
    } 
      const formdata = new FormData()
      formdata.append('nombre', nombre)
      formdata.append('descripcion', descripcion)
      formdata.append('id_unidad', id_unidad)
      formdata.append('meta', valor)
      
      fetch(_servidorapi+'crearplanobjetivo', {
        method: 'POST',
        body: formdata
      })
      .then(res => res.text())
      .then(res => actividadCreadaProyecto())
      .catch(err => {
        console.error('EL Error es:'+err)
      })
          // Reiniciar los valores
          setNombre('');
          setDescripcion('');
          setValor('');
          setUnidad('');
          setShowModal1(false);
          setisEditMode1(false);
  }

  const handleSaveEditClick = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 ||id_unidad <= 0  || valor <= 0 ) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('id_planobjetivo', id_planobjetivo)
    formdata.append('nombre', nombre)
    formdata.append('descripcion', descripcion)
    formdata.append('meta', valor)
    formdata.append('id_unidad', id_unidad)

    fetch(_servidorapi+'editarplanobjetivo', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ActividadActualizada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setDescripcion('');
        setValor('');
        setUnidad('');
        setShowModal1(false);
        setisEditMode1(false);

  }
  const handleActividadClick = () => {
    setShowModal1(true);
    setisEditMode1(false);
  };


  const handleEditClick = async (idObjetivox) => {
    setObjetivo(idObjetivox)
    const url = _servidorapi+'obtenerplanobjetivo/?id_planobjetivo=' + encodeURIComponent(idObjetivox);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setNombre(data2[0].nombre);
    setDescripcion(data2[0].descripcion);
    setValor(data2[0].meta);
    setUnidad(data2[0].id_unidad);
    setShowModal1(true);
    setisEditMode1(true);
};

  const handleCancelClick = () => {
    // Reiniciar los valores
    setNombre('');
    setDescripcion('');
    setShowModal1(false);
    setisEditMode1(false);
  };
 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id_planactividad = searchParams.get('id_planactividad');

  const borrarActividad = (xidactividadx) => {
    const formdata = new FormData()
    formdata.append('id_planobjetivo', xidactividadx)
    fetch(_servidorapi+'eliminarplanobjetivos', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => actividadBorrada())
    .catch(err => {
      console.error(err)
    })
        


   }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi+'maestrosplanobjetivos');
        const jsonData = await response.json();
        setObjetivos(jsonData);

        
        const responseUnidad = await fetch(_servidorapi+'maestrounidades');
        const jsonUnidad = await responseUnidad.json();
        const jsonOptionUnidad =  [
          { value: "", label: "Unidad de Medida" },
          ...jsonUnidad.map(item => ({ value: item.id_tipounidad, label: item.nombre })),
        ];
        setOptionsUnidad(jsonOptionUnidad);

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    
    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página

    return (
      <div className='main-container'>
        <h1 style={{ display: 'inline' }}>Objetivos Institucionales Plan de Accion</h1>
       
  
              <div className="card-header">
                <button onClick={handleActividadClick} className="btn btn-primary float-end">Adicionar Objetivos</button>
              </div>
              <div className="card-content">
              <table className="table table-dark table-striped table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th className="col-1">Objetivo</th>
                      <th className="col-3">Descripcion</th>
                      <th className="col-1">Medida</th>
                      <th className="col-1">Meta</th>
                      <th className="col-3">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {optionsObjetivos.map((asistencia, index) => (
                      <tr key={asistencia.id_planobjetivo}>
                        <td>{asistencia.nombre}</td>
                        <td>{asistencia.descripcion}</td>
                        <td>{asistencia.unidad}</td>
                        <td>{asistencia.meta}</td>
                        <td>
                        <button className="btn btn-danger" onClick={() => borrarActividad(asistencia.id_planobjetivo)}>
                          Borrar
                        </button>
                        <button className="btn btn-success" onClick={() => handleEditClick(asistencia.id_planobjetivo)}>
                          Editar
                        </button>
                        <button className='btn btn-info' onClick={() => handlePresupuestoClick(asistencia.id_planobjetivo)}>Actividades</button>
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
    
    
      </div>
      <Modal show={showModal1} onHide={() => setShowModal1(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode1 ? 'Editar Objetivo Institucional' : 'Agregar Objetivo Institucional'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formDescripcion">
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              as="textarea" 
              rows={3} 
              placeholder="Objeto General"
              value={descripcion} 
              onChange={e => setDescripcion(e.target.value)} 
            />
          </Form.Group>
          <Form.Group controlId="formUserType2">
              <Form.Label>Unidad de Med.</Form.Label>
              <Form.Control
                as="select"
                value={id_unidad}
                onChange={e => setUnidad(e.target.value)}
              >
                {optionsUnidad.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formLugar">
              <Form.Label>Meta</Form.Label>
              <Form.Control
                type="number"
                placeholder="Meta"
                value={valor}
                onChange={e => setValor(e.target.value)}
              />
            </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode1 ? handleSaveEditClick : handleSaveClick1}>{isEditMode1 ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
 

      </div>
    );
}

export default Planobjetivos

