import React, { useEffect, useState } from 'react';
import Comestrategias2023 from '../Components/Comestrategias2023'
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
  Label,
  ResponsiveContainer
} from 'recharts';
import "./Maestros.css";

const _servidorapi = process.env.REACT_APP_URL_API;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Estadisticaestrategia2023 = () => {
  const formatValueAsCurrency = (value) => {
    if (typeof value !== 'undefined' && value !== null) {
      return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    }
    return 'N/A'; // Otra cadena o valor predeterminado si el valor es indefinido o nulo
  };

  const [chartData, setChartData] = useState([]);

  const CustomLegend = () => (
    <ul>
      {chartData.map((entry, index) => (
        <li key={`legend-${index}`} style={{ color: COLORS[index % COLORS.length] }}>
          {entry.estrategia}: {formatValueAsCurrency(entry.valor)}
        </li>
      ))}
    </ul>
  );

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi + 'participacionestrategias2023');
        if (!response.ok) {
          throw new Error(`Error de red: ${response.status}`);
        }
        const jsonData = await response.json();
        setChartData(jsonData);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <main className='main-container'>
      <div className='main-title'>
        <h3>PARTICIPACION EN EL PRESUPUESTO 2023 POR ESTRATEGIA</h3>
      </div>
      <ResponsiveContainer width="100%" height="60%">
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={200}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="valor"
            nameKey="estrategia"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label
              content={({ value, name, percent }) => {
                const formattedValue = formatValueAsCurrency(value);
                return `${name}: ${formattedValue} (${(percent * 100).toFixed(0)}%)`;
              }}
            />
          </Pie>
          <Tooltip
            formatter={(value) => formatValueAsCurrency(value)}
          />
          <Legend content={<CustomLegend />} layout="vertical" align="right" verticalAlign="middle" iconSize={30} />
        </PieChart>
      </ResponsiveContainer>

      <Comestrategias2023 />
    </main>
  );
}

export default Estadisticaestrategia2023;
