import React, { useEffect, useState, PureComponent  } from 'react'
import "./Maestros.css";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const _servidorapi = process.env.REACT_APP_URL_API


const ComPresupuestodetalle = () => {
  const [chartData,setChartData] = useState([]); 
  useEffect(() => {
    console.log('PRESUPUESTO EJECUCCION')
    const fetchData = async () => {
      try {
        console.log(_servidorapi)
        const response = await fetch(_servidorapi+'presupuestovigenciasejecuccion');
        if (!response.ok) {
            throw new Error(`Error de red: ${response.status}`);
          }
        const jsonData = await response.json();
        setChartData(jsonData);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
    console.log(chartData)
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página

  return (
    <div className='main-container'>
    {chartData && chartData.length > 0 && (
    <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="vigencia" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="valor" fill="#8884d8" />
          <Bar dataKey="ejecucion" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
      )}
      </div>
  )
}

export default ComPresupuestodetalle