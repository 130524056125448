import { useState } from 'react'
import './App.css'
import Header from './Header'
import Sidebar from './Sidebar'
import Home from './Home'
import {BrowserRouter,Route,Routes} from 'react-router-dom'
import Plan from './Pages/Plan'
import Proyectos from './Pages/Proyectos'
import Contratos from './Pages/Contratos'
import Presupuesto from './Pages/Presupuesto'
import Estrategias from './Pages/Estrategias'
import Actividades from './Pages/Activiadades'
import Avances from './Pages/Avances'
import Tiposprocesos from './Pages/Tiposprocesos'
import Tiposcontratos from './Pages/Tiposcontratos'
import Unidades from './Pages/Unidades'
import Contratistas from './Pages/Contratistas'

import Dependencias from './Pages/Dependencias'
import Responsables from './Pages/Responsables'
import ContratoActividad from './Pages/Contratoactividades'
import Fuentes from './Pages/Fuentes'
import Proyectoactividades from './Pages/Presupuestoactividades'
import Objetivosgenerales from './Pages/Objetivosgenerales'
import Objetivosespecificos from './Pages/Objetivosespecificos'
import Acciones from './Pages/Acciones'
import Login from './Login'
import Objetivospe from './Pages/Objetivospe'
import Actividadesoperativas from './Pages/Actividadesperativas'
import Contratopresupuesto from './Pages/Contratopresupuesto'
import Actividadespresupuesto from './Pages/Actividadespresupuesto'
import Proyectoactividadpresupuesto from './Pages/Proyectoactividadpresupuesto'
import Actividadesoperativaspresupuesto from './Pages/Actividadesoperativaspresupuesto'
import Usuarios from './Pages/Usuarios'
import Avancescontratos from './Pages/Avancescontratos'
import Avancecontratoactividades from './Pages/Avancecontratoactividades'
import Avancesoperativas from './Pages/Avanceoperativivas'
import EstaPresupuesto from './Pages/EstaPresupuesto'
import EstaProyectos from './Pages/EstaProyectos'
import ComPresupuestodetalle from './Pages/ComPresupuestodetalle'
import Comproyectodetalle from './Pages/Comproyectodetalle'
import Comcontratosdetalle from './Pages/Comcontratosdetalle'
import Actividadesporcentajes from './Pages/Actividadesporcentajes'
import Proyectoobjetivos from './Pages/Proyectoobjetivos'
import Planuno from './Pages/Planuno'
import Planactividades from './Pages/Planactividades'
import Planobjetivos from './Pages/Planobjetivos'
import Contratosestados from './Pages/Contratosestados'
import Objetivospepresupuesto from './Pages/Objetivospepresupuesto'
import Planactividadespresupuesto from './Pages/Planactividadespresupuesto'
import Estadisticaestrategia2023 from './Pages/Estadisticaestrategia2023'

//const isLoggedIn = localStorage.getItem('logueado') === 'true';
function Principal() {
  //const [isLoggedInOk, setisLoggedInOk] = useState(null)
  var isLoggedIn = localStorage.getItem('logueadoco') === 'true';
  console.log(' VA EN '+isLoggedIn)
 // setisLoggedInOk(isLoggedIn)
  //console.log(isLoggedInOk)
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }

  const volverAPrincipal = () => {
    console.log('Regrese')
    localStorage.setItem('logueadoco', 'true');
    //setisLoggedInOk(true)
    console.log(isLoggedIn)
  };
  

  return (
    <div>
      {isLoggedIn ? (
         <div className='grid-container'>
         <BrowserRouter>
           <Header OpenSidebar={OpenSidebar}/>
           <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar}/>
            <Routes>
              <Route path='/' element = {<Home />}/>
              <Route path='/plan' element = {<Plan />}/>
              <Route path='/proyectos' element = {<Proyectos />}/>
              <Route path='/contratos' element = {<Contratos />}/>
              <Route path="/contrato/contratoactividad" element={<ContratoActividad />} />
              <Route path="/contrato/contratopresupuesto" element={<Contratopresupuesto />} />
              <Route path="/proyecto/proyectoactividad" element={<Proyectoactividades />} />
              <Route path="/avance/avancecontratoactividades" element={<Avancecontratoactividades />} />
              <Route path="/actividades/actividadpresupuesto" element={<Actividadespresupuesto />} />
              <Route path="/actividades/actividadporcentaje" element={<Actividadesporcentajes />} />
              <Route path="/proyecto/proyectoactividad/proyectoactividadpresupuesto" element={<Proyectoactividadpresupuesto/>} />
              <Route path="/proyecto/proyectoobjetivos" element={<Proyectoobjetivos/>} />
              <Route path="/planactividades" element={<Planactividades/>} />
              <Route path="/planobjetivos" element={<Planobjetivos/>} />
              <Route path="/planactividadespresupuesto" element={<Planactividadespresupuesto/>} />
              <Route path="contratosestados" element={<Contratosestados/>} />
              <Route path="objetivospepresupuesto" element={<Objetivospepresupuesto/>} />
              <Route path='/presupuesto' element = {<Presupuesto />}/>
              <Route path='/estrategias' element = {<Estrategias />}/>
              <Route path='/planuno' element = {<Planuno />}/>
              <Route path='/compresupuestodetalle' element = {<ComPresupuestodetalle />}/>
              <Route path='/estadisticaestrategia2023' element = {<Estadisticaestrategia2023 />}/>
              <Route path='/comproyectodetalle' element = {<Comproyectodetalle />}/>
              <Route path='/comcontratosdetalle' element = {<Comcontratosdetalle />}/>
              <Route path='/avancescontratos' element = {<Avancescontratos />}/>
              <Route path='/avancesoperativos' element = {<Avancesoperativas />}/>
              <Route path='/usuarios' element = {<Usuarios />}/>
              <Route path='/estapresupuesto' element = {<EstaPresupuesto />}/>
              <Route path='/estaproyectos' element = {<EstaProyectos />}/>
              <Route path='/objetivospe' element = {<Objetivospe />}/>
              <Route path='/actividades' element = {<Actividades />}/>
              <Route path='/actividadesoperativas' element = {<Actividadesoperativas />}/>
              <Route path='/actividadesoperativas/presupuestooperativas' element = {<Actividadesoperativaspresupuesto />}/>
              <Route path='/avances' element = {<Avances />}/>
              <Route path='/objetivosgenerales' element = {<Objetivosgenerales />}/>
              <Route path='/objetivosespecificos' element = {<Objetivosespecificos />}/>
              <Route path='/accion' element = {<Acciones />}/>    
              <Route path='/tiposprocesos' element = {<Tiposprocesos />}/>
              <Route path='/tiposcontratos' element = {<Tiposcontratos />}/>
              <Route path='/unidades' element = {<Unidades />}/>
              <Route path='/dependencias' element = {<Dependencias />}/>
              <Route path='/fuentes' element = {<Fuentes />}/>
              <Route path='/responsables' element = {<Responsables />}/>
              <Route path='/contratistas' element = {<Contratistas />}/>
   
           </Routes>
         </BrowserRouter>
       </div>
        
      ) : (
        <Login volverAPrincipal={volverAPrincipal} />
      )}
    </div>
   
  )
}

export default Principal
