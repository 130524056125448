import React from 'react'
import {BsActivity} from 'react-icons/bs'
import { Link } from 'react-router-dom'; 

const ComEjecuccion = () => {
  return (
    <div>
      <div className='card'  style={{ backgroundColor: 'gray', color: 'white' }}>
       <Link to="/compresupuestodetalle" className="card-link">
        <div className='card-inner'>
            <h3 style={{ color: 'white' }}>Ejecucion</h3>
            <BsActivity className='card-iicon'/>
        </div>
        <h1>25%</h1>
        </Link>
     </div>
  </div>
  )
}

export default ComEjecuccion