import React, { Component } from 'react';
import { BsCashCoin } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const _servidorapi = process.env.REACT_APP_URL_API;

class ComPresupuesto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datax: [],
      valorpresupuesto: 0,
    };
  }

  formattedValorPresupuesto() {
    return new Intl.NumberFormat('es-CO', {
      style: 'currency',
      currency: 'COP',
    }).format(this.state.valorpresupuesto);
  }

  fetchDatax = async () => {
    try {
      const response = await fetch(_servidorapi + 'presupuestovigencias');
      const jsonData = await response.json();
      let total = 0;
      for (const item of jsonData) {
        total += item.valor;
      }
      this.setState({ datax: jsonData, valorpresupuesto: total });
      console.log('Total: ' + total);
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    }
  };

  componentDidMount() {
    console.log('Componente Presupuesto1 - componentDidMount');
    this.fetchDatax();
  }

  render() {
    return (
      <div>
        <div className='card' style={{ backgroundColor: 'red', color: 'white' }}>
          <Link to="/compresupuestodetalle" className="card-link">
            <div className='card-inner'>
              <h3 style={{ color: 'white' }}>Presupuesto</h3>
              <BsCashCoin className='card-iicon'/>
            </div>
            <h1>{this.formattedValorPresupuesto()}</h1>
          </Link>
        </div>
      </div>
    );
  }
}

export default ComPresupuesto;
