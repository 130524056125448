import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Comcontratosdetalle = () => {

    const avanzarActividad = (index) => {
        
      };
    const data = [
        { nombre: 'AAS', porcentaje: 25 },
        { nombre: 'ssas', porcentaje: 50 },
        { nombre: 'A212', porcentaje: 75 },
        { nombre: 'Awww', porcentaje: 90 },
      ];
      const totalPorcentaje = data.reduce((acc, item) => acc + item.porcentaje, 0);

      return (
        <div className="container">
          <h1>Avances Contratos</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Contrato</th>
                <th>Porcentaje</th>
                <th>Actividades</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.nombre}</td>
                  <td>
                    <div className="progress">
                      <div
                        className={`progress-bar ${item.porcentaje === 100 ? 'bg-success' : 'bg-primary'}`}
                        role="progressbar"
                        style={{ width: `${item.porcentaje}%` }}
                        aria-valuenow={item.porcentaje}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {item.porcentaje}%
                      </div>
                    </div>
                  </td>
                  <td className="text-center"><button
                    className="btn btn-primary"
                    onClick={() => avanzarActividad(index)}
                  >
                    Ver actividades
                  </button></td>
                </tr>
              ))}
            
            </tbody>
          </table>
        </div>
      );
    };
    
    

export default Comcontratosdetalle