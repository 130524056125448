import React, { useState, useEffect } from 'react';
import "./Maestros.css";
const _servidorapi = process.env.REACT_APP_URL_API;

function TreeView({ data }) {
  return (
    <ul>
      {data.map(item => (
        <li key={item.id}>
          {item.name}
          {item.children && item.children.length > 0 && (
            <TreeView data={item.children} />
          )}
        </li>
      ))}
    </ul>
  );
}

function PlanUno() {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch(_servidorapi + 'maestroestrategias');
        const estrategiasData = await response1.json();

        const response2 = await fetch(_servidorapi + 'maestroobjetivosgenerales');
        const objetivosGeneralesData = await response2.json();

        const response3 = await fetch(_servidorapi + 'maestroobjetivosespecificos');
        const objetivosEspecificosData = await response3.json();

        const response4 = await fetch(_servidorapi + 'maestroacciones');
        const accionesData = await response4.json();

        const response5 = await fetch(_servidorapi + 'maestroactividades');
        const actividadesData = await response5.json();

        const treeData = estrategiasData.map(estrategia => {
          const objetivosGenerales = objetivosGeneralesData.filter(obj => obj.estrategiaId === estrategia.id);
          return {
            id: estrategia.id,
            name: estrategia.nombre,
            children: objetivosGenerales.map(objetivoGeneral => {
              const objetivosEspecificos = objetivosEspecificosData.filter(obj => obj.objetivoGeneralId === objetivoGeneral.id);
              return {
                id: objetivoGeneral.id,
                name: objetivoGeneral.nombre,
                children: objetivosEspecificos.map(objetivoEspecifico => {
                  const acciones = accionesData.filter(accion => accion.objetivoEspecificoId === objetivoEspecifico.id);
                  return {
                    id: objetivoEspecifico.id,
                    name: objetivoEspecifico.nombre,
                    children: acciones.map(accion => {
                      const actividades = actividadesData.filter(actividad => actividad.accionId === accion.id);
                      return {
                        id: accion.id,
                        name: accion.nombre,
                        children: actividades.map(actividad => ({
                          id: actividad.id,
                          name: actividad.nombre,
                          children: [], // No hay más niveles de hijos
                        })),
                      };
                    }),
                  };
                }),
              };
            }),
          };
        });

        setTreeData(treeData); // Establece los datos en el estado
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
            <div className='px-6 py-4'>
      <h1>Ejecución por año del PNSV</h1>
      <TreeView data={treeData} />
      </div>
    </div>
  );
}

export default PlanUno;