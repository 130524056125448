import React, { useEffect, useState } from 'react'
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import classNames from 'classnames'
import { rankItem } from '@tanstack/match-sorter-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faChevronUp,faChevronRight,faAngleDoubleRight,faChevronLeft,faAngleDoubleLeft,faArrowUp,faArrowDown} from '@fortawesome/free-solid-svg-icons';

const _servidorapi = process.env.REACT_APP_URL_API

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)

  addMeta({ itemRank })

  return itemRank.passed
}

const DebouncedInput = ({ value: keyWord, onChange, ...props }) => {
  const [value, setValue] = useState(keyWord);
  // console.log(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log('Filterd');
      onChange(value);
    }, 500);
  
    return () => clearTimeout(timeout);
  }, [onChange, value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

const Responsables= () => {
  const [validNombres, setValidNombres] = useState(true);
  const [validCedula, setValidCedula] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [isEditMode, setisEditMode] = useState(false);
  
  const Swal = require('sweetalert2')
  const [showModal, setShowModal] = useState(false);
  const [nombres, setNombres] = useState('');
  const [idresponsable, setIdresponsable] = useState('');
  const [cedula, setCedula] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [direccion, setDireccion] = useState('');




  // Inicializar el mapa de Leaflet

  
  const validateNombres = () => {
    const isValid = nombres.trim() !== ''; // Verifica si no es una cadena vacía o solo contiene espacios en blanco
    setValidNombres(isValid);
  };
  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailRegex.test(email);
    setValidEmail(isValid);
  };

  const validateCedula = () => {
    const isValid = !isNaN(cedula) && parseInt(cedula) >= 0; // Verifica si es numérico y mayor o igual a cero
    setValidCedula(isValid);
  };

  const ResponsableCreado = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Responsable Registrado(a)',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroresponsables');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };



  const ResponsableActualizado = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'success',
      title: 'Responsable Actualizado(a)',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroresponsables');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };

  const handleAddClick = () => {
    setNombres('');
    setCedula('');
    setEmail('');
    setTelefono('');
    setDireccion('')
    setShowModal(true);
    setisEditMode(false);
  };

  const handleEditClick = async (idResponsablex) => {
      setIdresponsable(idResponsablex)
      const url = _servidorapi+'obtenerresponsable/?id_responsable=' + encodeURIComponent(idResponsablex);
      const response = await fetch(url, { method: 'GET' });
      const data2 = await response.json();
      setNombres(data2[0].nombre);
      setCedula(data2[0].cedula);
      setEmail(data2[0].email);
      setTelefono(data2[0].celular);
      setDireccion(data2[0].direccion);
       setisEditMode(true);
      setShowModal(true);
  };





  const handleSaveClick = () => {
    if (nombres.trim().length === 0 || email.trim().length === 0 || telefono.trim().length === 0 || cedula.trim().length === 0 || direccion.trim().length === 0) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('email', email)
    formdata.append('celular', telefono)
    formdata.append('direccion', direccion)
    formdata.append('nombre', nombres)
    formdata.append('cedula', cedula)


    fetch(_servidorapi+'crearresponsable', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ResponsableCreado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombres('');
        setCedula('');
        setEmail('');
        setTelefono('');
        setShowModal(false);
        setisEditMode(false);

  }
  
  const faltandatos = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Faltan datos por Llenar',
      text: 'Son Necesarios',
    }).then(async () => {
    });
  
    return null;
  };

  const handleSaveEditClick = () => {
    if (nombres.trim().length === 0 || email.trim().length === 0 || telefono.trim().length === 0 || cedula.trim().length === 0 || direccion.trim().length === 0) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    console.log(idresponsable);
    formdata.append('id_responsable', idresponsable)
    formdata.append('email', email)
    formdata.append('celular', telefono)
    formdata.append('direccion', direccion)
    formdata.append('nombre', nombres)
    formdata.append('cedula', cedula)

    fetch(_servidorapi+'editarresponsable', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ResponsableActualizado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombres('');
        setCedula('');
        setEmail('');
        setTelefono('');

        setShowModal(false);
        setisEditMode(false);

  }
  const ResponsableEliminado = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Responsable Borrado',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroresponsables');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };
  
  const handleEliminarClick = (idResponsablex) => {
    const formdata = new FormData()
    formdata.append('id_responsable', idResponsablex)

    fetch(_servidorapi+'eliminarresponsable', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ResponsableEliminado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombres('');
        setShowModal(false);
        setisEditMode(false);

  }


  const handleCancelClick = () => {
    // Reiniciar los valores
    setNombres('');
    setCedula('');
    setEmail('');
    setTelefono('');
    setShowModal(false);
    setisEditMode(false);
  };
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  console.log(globalFilter);

  const columns = [
    {
      accessorKey: 'nombre',
      header: () => <span>Nombre</span>,
      cell: info => (
        <div style={{ width: '300px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'cedula',
      header: () => <span>Cedula</span>,
      cell: info => (
        <div style={{ width: '100px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'email',
      header: () => <span>Email</span>,
      cell: info => (
        <div style={{ width: '200px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'celular',
      header: () => <span>celular</span>,
      cell: info => (
        <div style={{ width: '100px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'direccion',
      header: () => <span>direccion</span>,
      cell: info => (
        <div style={{ width: '300px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'id_responsable',
      header: 'Acciones',
      cell: info => {
        const XSetidResponsable = info.getValue('id_responsable');
        return (
          <div className='d-flex align-items-center'>
          <button className='btn btn-danger' onClick={() => handleEliminarClick(XSetidResponsable)}>Eliminar</button>
          <button className='btn btn-primary' onClick={() => handleEditClick(XSetidResponsable)}>Editar</button>
        </div>
        )
      },
      enableSorting: false
    }
  ]

  const getStateTable = () => {
    const totalRows = table.getFilteredRowModel().rows.length;
    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;
    const rowsPerPage = table.getRowModel().rows.length;

    const firstIndex = (pageIndex * pageSize) + 1;
    const lastIndex = (pageIndex * pageSize) + rowsPerPage;

    return {
      totalRows,
      firstIndex,
      lastIndex
    }
  }
  
   
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting
    },
    initialState: {
      pagination: {
        pageSize: 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi+'maestroresponsables');
        const jsonData = await response.json();
        setData(jsonData);     
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página

  return (
    
    <div className='main-container'>
      <div className='px-6 py-4'>
    <div className="headerm" style={{ display: 'flex', alignItems: 'center' }}>
       <Button onClick={handleAddClick} variant="primary">+</Button><h1 style={{ display: 'inline-block', marginRight: '10px' }}>Responsables</h1>
       </div>
      <div className='my-2 d-flex justify-content-end'>
      <div className="position-relative">
  <div className="input-group">
    <DebouncedInput
      type="text"
      value={globalFilter ?? ''}
      onChange={value => setGlobalFilter(String(value))}
      className="form-control"
      placeholder="Buscar..."
    />
    <button className="btn btn-primary" type="button">
      <FontAwesomeIcon icon={faSearch} className="text-light" />
    </button>
  </div>
</div>
      </div> 
      <div className='overflow-auto'>
          <table className='table table-striped w-full min-w-[560px]'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="border-bottom border-gray-300 text-gray-600 bg-gray-100">
                  {headerGroup.headers.map(header => (
                    <th key={header.id} className="py-2 px-4 text-left text-uppercase">
                      {header.isPlaceholder
                        ? null
                        :
                        <div
                          className={classNames({
                            'cursor-pointer select-none d-flex justify-content-between': header.column.getCanSort(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FontAwesomeIcon icon={faArrowUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />,
                            desc: <FontAwesomeIcon icon={faArrowDown} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          }[header.column.getIsSorted()] ?? (header.column.getCanSort() ? <FontAwesomeIcon icon={faChevronUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          : null)}
                        </div>
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="text-gray-600 hover:bg-slate-100">
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="py-2 px-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-between space-y-4 text-center'>
          <div className='d-flex align-items-center gap-2'>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faChevronLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
      
            {table.getPageOptions().map((value, key) => (
              <button key={key}
                className={classNames({
                  "btn btn-light rounded border border-gray-300 font-weight-bold disabled:hover:bg-white disabled:hover:text-gray-300": true,
                  "bg-indigo-200 text-indigo-700": value === table.getState().pagination.pageIndex
                })}
                onClick={() => table.setPageIndex(value)}>
                {value + 1}
              </button>
            ))}
      
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}>
            <FontAwesomeIcon icon={faChevronRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}>
              <FontAwesomeIcon icon={faAngleDoubleRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
          </div>
          <div className='text-gray-600 font-weight-bold'>
            {getStateTable().firstIndex}&nbsp;
            a {getStateTable().lastIndex}&nbsp;
            de {getStateTable().totalRows} 
          </div>
          <div className="d-inline-block">
            <select
              className="form-select text-muted border border-muted rounded-pill outline-primary py-2"
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}>
            <option value="5">5 pág.</option>
            <option value="10">10 pág.</option>
            <option value="20">20 pág.</option>
            <option value="25">25 pág.</option>
            <option value="50">50 pág.</option>
          </select>
          </div>
     </div>
     <Modal show={showModal} onHide={() => setShowModal(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode ? 'Editar Responsable' : 'Agregar Responsable'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
           <Form.Group controlId="formName">
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          type="text"
          placeholder="Nombre"
          value={nombres}
          onChange={e => setNombres(e.target.value)}
          onBlur={validateNombres} // Validar al perder el foco del input
          isInvalid={!validNombres} // Aplicar estilo de error si está vacío
        />
        <Form.Control.Feedback type="invalid">
          Por favor, introduce un nombre válido.
        </Form.Control.Feedback>
      </Form.Group>
            <Form.Group controlId="formId">
        <Form.Label>Cedula</Form.Label>
        <Form.Control
          type="text"
          placeholder="Cedula"
          value={cedula}
          onChange={e => setCedula(e.target.value)}
          onBlur={validateCedula} // Validar al perder el foco del input
          isInvalid={!validCedula} // Aplicar estilo de error si no es numérico
        />
        <Form.Control.Feedback type="invalid">
          Por favor, introduce un valor numérico válido.
        </Form.Control.Feedback>
      </Form.Group>
            <Form.Group controlId="formEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          onBlur={validateEmail} // Validar al perder el foco del input
          isInvalid={!validEmail} // Aplicar estilo de error si no es un email válido
        />
        <Form.Control.Feedback type="invalid">
          Por favor, introduce un email válido.
        </Form.Control.Feedback>
      </Form.Group>
            <Form.Group controlId="formDireccion">
              <Form.Label>Direccion</Form.Label>
              <Form.Control
                type="text"
                placeholder="Direccion"
                value={direccion}
                onChange={e => setDireccion(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                placeholder="Celular"
                value={telefono}
                onChange={e => setTelefono(e.target.value)}
              />
            </Form.Group>
          

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode ? handleSaveEditClick : handleSaveClick}>{isEditMode ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
    </div>
    </div>
  
  )
}

export default Responsables