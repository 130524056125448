import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import md5 from "md5";
import withReactContent from 'sweetalert2-react-content'

const _servidorapi = process.env.REACT_APP_URL_API

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://soul-group.net/">
        Consultorias Oficiales
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();


export default function Login({ volverAPrincipal }) {
  const Swal = require('sweetalert2')
    const handleSubmit = async (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const passwordU = md5(data.get('password'));
      const emailU = data.get('email');
      const url = _servidorapi+'loginco/?email=' + encodeURIComponent(emailU) + '&password=' + encodeURIComponent(passwordU);
      const response = await fetch(url, { method: 'GET' });
      const data2 = await response.json();
      if (data2[0] && data2[0].clave) {
        const clave = data2[0].clave;
        localStorage.setItem('nivel', data2[0].acceso);
        if (clave === passwordU) {
          localStorage.setItem('emailusuario', emailU );
          localStorage.setItem('logueadoco', 'true');
          window.location.href = '/'; 
          volverAPrincipal();
        } else {
          clavenovalida();
        }
      } else {
        noregistrado();
      }
    };
  
    const clavenovalida = async () => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: 'success',
        title: 'La clave no es valida',
        text: 'Gracias',
      }).then(async () => {
      });
      return null;
    };

    const noregistrado = async () => {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: 'success',
        title: 'Usuario no Registrado',
        text: 'Gracias',
      }).then(async () => {
      });
      return null;
    };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/portada2.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Ingreso a Tablero de Control
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Aceptar
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}