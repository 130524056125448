import React, { useEffect, useState, PureComponent  } from 'react'
import '../App.css'
import { Chart } from "react-google-charts";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const _servidorapi = process.env.REACT_APP_URL_API

export const options = {
    title: "Proyectos",
  };

const Comproyectodetalle = () => {
  const [chartData,setChartData] = useState([]); 
  useEffect(() => {
    console.log('PRESUPUESTO EJECUCCION')
    const fetchData = async () => {
      try {
        console.log(_servidorapi)
        const response = await fetch(_servidorapi+'presupuestovigencias');
        if (!response.ok) {
            throw new Error(`Error de red: ${response.status}`);
          }
        const jsonData = await response.json();
        setChartData(jsonData);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
    console.log(chartData)
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página

  return (
    <div className='main-container'>
<Chart
  chartType="PieChart"
  data={[
    ['vigencia', 'valor'],
    ...chartData.map(d => [+"'"+d.vigencia+"'", d.valor])
  ]}
  options={options}
  width={"100%"}
  height={"400px"}
/>
</div>
  )
}

export default Comproyectodetalle