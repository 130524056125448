import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import "./Maestros.css";
import "../App.css";
const _servidorapi = process.env.REACT_APP_URL_API
const Avancecontratoactividades = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idavancex = searchParams.get('idavancecontratox');

  const idcontratox = searchParams.get('idcontratox')
  console.log('El contrato es :'+idcontratox)
  console.log('El avance es :'+idavancex)
  const Swal = require('sweetalert2')
  const [showModal1, setShowModal1] = useState(false);
  const [isEditMode1, setisEditMode1] = useState(false);
  const [actividadAvance, setaactividadAvance] = useState([]);
  const [id_actividad, setActividad] = useState('');
  const [id_avanceactividadcontrato, setAvanceactividad] = useState('');
  const [porcentaje, setPorcentaje] = useState('');
  const [cantidad, setCantidad] = useState('');
  const [valor, setValor] = useState('');
  const [optionsActividad, setOptionsActividad] = useState([]);
  const [nombreContrato, setNombreContrato] = useState('');

  const faltandatos = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Faltan datos por Llenar',
      text: 'Son Necesarios',
    }).then(async () => {
    });
  
    return null;
  };


  const actividadCreadaProyecto = async () => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          icon: 'success',
          title: 'Avance Registrado',
          text: 'Gracias',
        }).then(async () => {

          const fetchData = async () => {
            try {
              const url = _servidorapi+'obteneravanceactividades?id_avancecontrato=' + encodeURIComponent(idavancex);
              const response = await fetch(url, { method: 'GET' });
              const data = await response.json();
              setaactividadAvance(data);
      
            } catch (error) {
              console.error('Error al cargar los datos:', error);
            }
          };
          fetchData()
        
        });
      
        return null;
  };
  const ActividadActualizada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Avance Actualizado',
        text: 'Gracias',
      }).then(async () => {

        const fetchData = async () => {
          try {
            const url = _servidorapi+'obteneravanceactividades?id_avancecontrato=' + encodeURIComponent(idavancex);
            const response = await fetch(url, { method: 'GET' });
            const data = await response.json();
            setaactividadAvance(data);
    
          } catch (error) {
            console.error('Error al cargar los datos:', error);
          }
        };
        fetchData()
      });
    
      return null;
  };
  const actividadBorrada = async () => {
      const MySwalx = withReactContent(Swal);
      MySwalx.fire({
        icon: 'success',
        title: 'Avance Borrado',
        text: 'OK',
      }).then(async () => {
        const fetchData = async () => {
          try {
            const url = _servidorapi+'obteneravanceactividades?id_avancecontrato=' + encodeURIComponent(idavancex);
            const response = await fetch(url, { method: 'GET' });
            const data = await response.json();
            setaactividadAvance(data);
    
          } catch (error) {
            console.error('Error al cargar los datos:', error);
          }
        };
        fetchData()
      });
    
      return null;
  };
  const handleSaveClick1 = () => {
    if (cantidad.trim().length === 0 || valor.trim().length === 0 || porcentaje.trim().length === 0 || idavancex.trim().length === 0 || id_actividad.trim().length === 0 ) {
  faltandatos();
  return null
} 
      const formdata = new FormData()
      formdata.append('cantidad', cantidad)
      formdata.append('valor', valor)
      formdata.append('porcentaje', porcentaje)
      formdata.append('id_avancecontrato', idavancex)
      formdata.append('id_contratoactividad', id_actividad)
      
      fetch(_servidorapi+'crearavanceactividad', {
        method: 'POST',
        body: formdata
      })
      .then(res => res.text())
      .then(res => actividadCreadaProyecto())
      .catch(err => {
        console.error('EL Error es:'+err)
      })
          // Reiniciar los valores
          setCantidad('');
          setValor('');
          setActividad('');
          setShowModal1(false);
          setisEditMode1(false);
  }
  const handleSaveEditClick = () => {
    if (id_actividad <= 0 || valor <= 0  || cantidad <= 0  || porcentaje <= 0) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('id_avancecontratoactividad', id_avanceactividadcontrato)
    formdata.append('id_contratoactividad', id_actividad)
    formdata.append('valor', valor)
    formdata.append('cantidad', cantidad)
    formdata.append('porcentaje', porcentaje)

    fetch(_servidorapi+'editaravancecontratoactividad', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ActividadActualizada())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setCantidad('');
        setValor('');
        setActividad('');
        setShowModal1(false);
        setisEditMode1(false);

  }
  const handleActividadClick = () => {
    setShowModal1(true);
    setisEditMode1(false);
  };
  const handleEditClick = async (idActividady) => {
    setAvanceactividad(idActividady)
    setActividad(idActividady)
    const url = _servidorapi+'obteneravancecontratoactividad/?id_avancecontratoactividad=' + encodeURIComponent(idActividady);
    const response = await fetch(url, { method: 'GET' });
    const data2 = await response.json();
    setValor(data2[0].valor);
    setCantidad(data2[0].cantidad);
    setPorcentaje(data2[0].porcentaje);
    setActividad(data2[0].id_contratoactividad)
    setShowModal1(true);
    setisEditMode1(true);
};
  const handleCancelClick = () => {
    // Reiniciar los valores
    setShowModal1(false);
    setisEditMode1(false);
  };
  
  const borrarActividad = (xidactividadx) => {
    const formdata = new FormData()
    console.log('La ACtividaca borrar es la :'+xidactividadx)
    formdata.append('id_avancecontratoactividad', xidactividadx)
    fetch(_servidorapi+'eliminaravancecontratoactividad', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => actividadBorrada())
    .catch(err => {
      console.error(err)
    })

    
   }


  useEffect(() => {
       const fetchData = async () => {
      try {
        const url4 = _servidorapi+'obteneravancecontrato/?id_avancecontrato=' + encodeURIComponent(idavancex);
        const response4 = await fetch(url4, { method: 'GET' });
        const data4 = await response4.json();
        const idcontratox = (data4[0].id_contrato);
        const url3 = _servidorapi+'obtenercontrato/?id_contrato=' + encodeURIComponent(idcontratox);
        const response3 = await fetch(url3, { method: 'GET' });
        const data3 = await response3.json();
        setNombreContrato(data3[0].codigo+':'+data3[0].nombre);
        const urla = _servidorapi+'obtenerctividadescontratos?id_contrato=' + encodeURIComponent(idcontratox);
        const responsea = await fetch(urla, { method: 'GET' });
        const jsonActividad = await responsea.json();
        const jsonOptionActividad =  [
          { value: "", label: "Seleccione la Actividad del Plan" },
          ...jsonActividad.map(item => ({ value: item.id_contratoactividad, label: item.nombre })),
        ];
        setOptionsActividad(jsonOptionActividad);
        const url = _servidorapi+'obteneravanceactividades?id_avancecontrato=' + encodeURIComponent(idavancex);
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        setaactividadAvance(data);

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    fetchData()
    

  }, [idavancex, idcontratox]); // Agregamos las dependencias aquí

    return (
      <div className='main-container'>
        <h1 style={{ display: 'inline' }}>Avance Contrato: {nombreContrato}</h1>
       
  
              <div className="card-header">
                <button onClick={handleActividadClick} className="btn btn-primary float-end">Adicionar Actividad</button>
              </div>
              <div className="card-content">
              <table className="table table-dark table-striped table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th className="col-1">Actividad</th>
                      <th className="col-3">Cantidad</th>
                      <th className="col-2">Avance</th>
                      <th className="col-1">Porcentaje</th>
                      <th className="col-3">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actividadAvance.map((asistencia, index) => (
                      <tr key={asistencia.id_avancecontratoactividad}>
                        <td>{asistencia.nombre}</td>
                        <td style={{ textAlign: 'right' }}>
  {asistencia.cantidad.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</td>
                        <td style={{ textAlign: 'right' }}>{asistencia.valor.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td style={{ textAlign: 'right' }}>{asistencia.porcentaje.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td>
                        <button className="btn btn-danger" onClick={() => borrarActividad(asistencia.id_avancecontratoactividad)}>
                          Borrar
                        </button>
                        <button className="btn btn-success" onClick={() => handleEditClick(asistencia.id_avancecontratoactividad)}>
                          Editar
                        </button>
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
    
    
      </div>
      <Modal show={showModal1} onHide={() => setShowModal1(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode1 ? 'Editar Actividad' : 'Agregar Actividad'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="formUserType">
              <Form.Label>Actividad Contrato</Form.Label>
              <Form.Control
                as="select"
                value={id_actividad}
                onChange={e => setActividad(e.target.value)}
              >
                {optionsActividad.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
                        <Form.Group controlId="formPresupuesto">
              <Form.Label>Cantidad</Form.Label>
              <Form.Control
                type="number"
                placeholder="Cantidad"
                value={cantidad}
                onChange={e => setCantidad(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCelular">
              <Form.Label>Valor</Form.Label>
              <Form.Control
                type="number"
                placeholder="Valor en $"
                value={valor}
                onChange={e => setValor(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formCedula">
              <Form.Label>Porcentaje</Form.Label>
              <Form.Control
                type="number"
                placeholder="Segun Contratista"
                value={porcentaje}
                onChange={e => setPorcentaje(e.target.value)}
              />
            </Form.Group>  
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode1 ? handleSaveEditClick : handleSaveClick1}>{isEditMode1 ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
 

      </div>
    );
}

export default Avancecontratoactividades