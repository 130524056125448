import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Maestros.css";
import $ from "jquery";
import DataTable from "datatables.net-bs4";
import "datatables.net-autofill-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-colreorder-bs4";
import "datatables.net-fixedcolumns-bs4";
import "datatables.net-fixedheader-bs4";
import "datatables.net-keytable-bs4";
import "datatables.net-responsive-bs4";
import "datatables.net-rowgroup-bs4";
import "datatables.net-rowreorder-bs4";
import "datatables.net-scroller-bs4";
import "datatables.net-searchbuilder-bs4";
import "datatables.net-searchpanes-bs4";
import "datatables.net-select-bs4";
import "datatables.net-staterestore-bs4";
import "datatables.net-plugins/sorting/absolute.js";
import "datatables.net-fixedcolumns/js/FixedColumns.js";

export default function Avances() {
  let runOnce = false;
  const data = [
    {
      id: 1,
      Contrato: "0001",
      nombre: "Transito",
      valor: '$100.000.000.000',
      fecha_desde: '01/01/2022',
      valor_pago: '$50.000.000',
      fecha_pago: '01/10/2022',
      fecha_real: '01/15/2022',
    },
    {
      id: 2,
      Contrato: "0002",
      nombre: "Carretera X",
      valor: '$200.000.000.000',
      fecha_desde: '01/01/2022',
      valor_pago: '$100.000.000',
      fecha_pago: '01/15/2022',
      fecha_real: '01/15/2022',
    }
  ];

  useEffect(() => {
    if (!runOnce) {
      let table = new DataTable("#test", {
        FixedColumns: {
          leftColumns: 1
        },
        orderCellsTop: true,
        scrollY: "250px",
        scrollX: true,
        className: 'custom-color2',
        pageLength: 20,
        language: {
          lengthMenu: "Mostrar _MENU_ datos por pagina",
          info: "Mostrar paginas _PAGE_ de _PAGES_",
          className: 'custom-color2',
          zeroRecords: "No matching users found",
          emptyTable: "No users found",
          infoEmpty: "Showing 0 to 0 of 0 users",
          infoFiltered: ""
        },
        columnDefs: [
          {
            targets: -1,
            visible: false,
            className: "dt-body-center"
          },
          {
            targets: 0,
            className: "text-align-center"
          },
          {
            targets: 2,
            className: "text-align-center"
          },
          {
            targets: 3,
            className: "text-align-center"
          },
          {
            targets: 4,
            className: "text-align-center"
          }
        ]
      });
      data.forEach((row) => {
        const rowData = Object.keys(row).map((key) => row[key]);
        table.row.add(rowData).draw(false);
      });
      runOnce = true;
    }
  }, []);

  return (
    <main className='main-containerm'>
      <div className="headerm">
      <h1 style={{ display: 'inline-block', marginRight: '10px' }}>Pagos A Contratos</h1>
  <Button variant="primary" style={{ display: 'inline-block' }}>Nuevo</Button>
</div>
      
      <table id="test" className="table table-container table-dark">
        <thead className="thead-light">
          <tr>
            <th scope="leaderboard_table_header col">Interno</th>
            <th scope="leaderboard_table_header col">Contrato</th>
            <th scope="leaderboard_table_header col">Nombre</th>
            <th scope="leaderboard_table_header col">Valor</th>
            <th scope="leaderboard_table_header col">Fecha</th>
            <th scope="leaderboard_table_header col">Valor Pago</th>
            <th scope="leaderboard_table_header col">Fecha Pago</th>
            <th scope="leaderboard_table_header col">Fecha Pago3</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </main>
  );
}