import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form} from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from '@tanstack/react-table'
import classNames from 'classnames'
import { rankItem } from '@tanstack/match-sorter-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faChevronUp,faChevronRight,faAngleDoubleRight,faChevronLeft,faAngleDoubleLeft,faArrowUp,faArrowDown} from '@fortawesome/free-solid-svg-icons';

//const _servidorapi = 'https://server2.consultoriasoficiales.com/'
//const _servidorapi = 'http://localhost:9000/'
const _servidorapi = process.env.REACT_APP_URL_API

const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)
  addMeta({ itemRank })
  return itemRank.passed
}

const DebouncedInput = ({ value: keyWord, onChange, ...props }) => {
  const [value, setValue] = useState(keyWord);
  // console.log(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log('Filterd');
      onChange(value);
    }, 500);
  
    return () => clearTimeout(timeout);
  }, [onChange, value]);

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

const Actividadesoperativas = () => {
  const [isEditMode, setisEditMode] = useState(false);
  const [optionsDependencias, setOptionsDependencias] = useState([]);
  const [optionsResponsables, setOptionsResponsables] = useState([]);
  const [optionsActiviadades, setOptionsActividades] = useState([]);
  const Swal = require('sweetalert2')
  const [showModal, setShowModal] = useState(false);
  const [nombre, setNombre] = useState('');
  const [valor, setValor] = useState('');
  const [id_actividad, setIdActividad] = useState('');
  const [id_actividadoperativa, setIdActividadOperativa] = useState('');
  const [id_dependencia, setIdDependencia] = useState('');
  const [id_responsable, setIdResponsable] = useState('');
  const [descripcion, setDescripcion] = useState('');

  const faltandatos = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'error',
      title: 'Faltan datos por Llenar',
      text: 'Son Necesarios',
    }).then(async () => {
    });
  
    return null;
  };

  const objetivoCreado = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Actividad  Creada',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroactividadoperativa');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };

  const ObjetivoActualizado = async () => {
    const MySwalx = withReactContent(Swal);
    MySwalx.fire({
      icon: 'success',
      title: 'Actividad Actualizada',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroactividadoperativa');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };

  const handleAddClick = () => {
    setShowModal(true);
    setisEditMode(false);
  };

  const MaestroEliminada = async () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: 'success',
      title: 'Actividad Borrada',
      text: 'Gracias',
    }).then(async () => {
      const response = await fetch(_servidorapi+'maestroactividadoperativa');
      const jsonData = await response.json();
      setData(jsonData);
    });
  
    return null;
  };

  const handleEditClick = async (idactividadx) => {
      setIdActividadOperativa(idactividadx);
      const url = _servidorapi+'obteneractividadoperativa/?id_actividadoperativa=' + encodeURIComponent(idactividadx);
      const response = await fetch(url, { method: 'GET' });
      const data2 = await response.json();
      console.log(data2);
      setNombre(data2[0].nombre);
      setIdDependencia(data2[0].id_dependencia);
      setValor(data2[0].valor);
      setIdActividad(data2[0].id_actividad);
      setIdResponsable(data2[0].id_responsable);
      setDescripcion(data2[0].descripcion);
      setisEditMode(true);
      setShowModal(true);
  };

  const navigate = useNavigate();


const handleEliminarClick = (idActividadx) => {
  const formdata = new FormData()
  formdata.append('id_actividadoperativa', idActividadx)

  fetch(_servidorapi+'eliminaractividadoperativa', {
    method: 'POST',
    body: formdata
  })
  .then(res => res.text())
  .then(res => MaestroEliminada())
  .catch(err => {
    console.error(err)
  })
      // Reiniciar los valores
      setNombre('');
      setShowModal(false);
      setisEditMode(false);

}

  const handleSaveClick = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 || valor <= 0 || id_dependencia <= 0 || id_responsable <= 0 || 
        id_actividad <= 0 ) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('nombre', nombre)
    formdata.append('descripcion', descripcion)
    formdata.append('valor', valor)
    formdata.append('id_dependencia', id_dependencia)
    formdata.append('id_responsable', id_responsable)
    formdata.append('id_actividad', id_actividad)


    fetch(_servidorapi+'crearactividadoperativa', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => objetivoCreado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setDescripcion('');
        setValor('');
        setIdDependencia('');
        setIdResponsable('');
        setIdActividad('');
        setShowModal(false);
        setisEditMode(false);
  }
  
  const handleSaveEditClick = () => {
    if (nombre.trim().length === 0 || descripcion.trim().length === 0 || valor <= 0 || id_dependencia <= 0 || id_responsable <= 0 || 
        id_actividad <= 0 ) {
      faltandatos();
      return null
    } 
    const formdata = new FormData()
    formdata.append('id_actividadoperativa', id_actividadoperativa)
    formdata.append('id_dependencia', id_dependencia)
    formdata.append('nombre', nombre)
    formdata.append('valor', valor)
    formdata.append('id_responsable', id_responsable)
    formdata.append('id_actividad', id_actividad)
    formdata.append('descripcion', descripcion)
    fetch(_servidorapi+'editaractividadoperativa', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => ObjetivoActualizado())
    .catch(err => {
      console.error(err)
    })
        // Reiniciar los valores
        setNombre('');
        setDescripcion('');
        setValor('');
        setIdActividad('');
        setIdDependencia('');
        setIdResponsable('');
        setShowModal(false);
        setisEditMode(false);
  }
  

  const handleCancelClick = () => {
    // Reiniciar los valores
    setNombre('');
        setDescripcion('');
        setIdDependencia('');
        setValor('');
        setIdActividad('');
    setShowModal(false);
    setisEditMode(false);
  };

  const handlePresupuestoClick = async (idactividadx) => {
    console.log('No ENTRA');
    navigate(`/actividadesoperativas/presupuestooperativas?idcontratox=${idactividadx}`);
    
  };
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  console.log(globalFilter);

  const columns = [
    {
      accessorKey: 'nombre',
      header: () => <span>Actividad</span>,
      cell: info => (
        <div style={{ width: '200px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'objetivo',
      header: () => <span>Objetivo Plan de Accion</span>,
      cell: info => (
        <div style={{ width: '300px', textAlign: 'left' }}><span className='font-bold' >{info.getValue()}</span></div>
      ),
    },
    {
      accessorKey: 'valor',
      header: () => <span>Valor</span>,
      
      cell: info => (
        <div style={{ width: '150px', textAlign: 'right' }}>
  <span className='font-bold'>
    {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(info.getValue())}
  </span>
</div>
      ),
    },
    {
      accessorKey: 'id_actividadoperativa',
      header: 'Acciones',
      cell: info => {
        const XSetidespecifico = info.getValue('id_actividadoperativa');
        return (
          <div className='d-flex align-items-center'>
          <button className='btn btn-primary' onClick={() => handleEditClick(XSetidespecifico)}>Editar</button>
          <button className='btn btn-danger' onClick={() => handleEliminarClick(XSetidespecifico)}>Eliminar</button>
          <button className='btn btn-success' onClick={() => handlePresupuestoClick(XSetidespecifico)}>Presupuesto</button>
        </div>
        )
      },
      enableSorting: false
    }
  ]

  const getStateTable = () => {
    const totalRows = table.getFilteredRowModel().rows.length;
    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;
    const rowsPerPage = table.getRowModel().rows.length;

    const firstIndex = (pageIndex * pageSize) + 1;
    const lastIndex = (pageIndex * pageSize) + rowsPerPage;

    return {
      totalRows,
      firstIndex,
      lastIndex
    }
  }
  
   
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting
    },
    initialState: {
      pagination: {
        pageSize: 10
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(_servidorapi+'maestroactividadoperativa');
        const jsonData = await response.json();
        setData(jsonData);
        const responseDependencias = await fetch(_servidorapi+'maestrodependencias');
        const jsoDependencias = await responseDependencias.json();
        const jsonOptionDependencias =  [
          { value: "", label: "Seleccionar la Depednencia" },
          ...jsoDependencias.map(item => ({ value: item.id_dependencia, label: item.nombre })),
        ];
        setOptionsDependencias(jsonOptionDependencias);
        const responseResponsables = await fetch(_servidorapi+'maestroresponsables');
        const jsoResponsables = await responseResponsables.json();
        const jsonOptionResponsables =  [
          { value: "", label: "Seleccionar el Responsable" },
          ...jsoResponsables.map(item => ({ value: item.id_responsable, label: item.nombre })),
        ];
        setOptionsResponsables(jsonOptionResponsables);


        const responseActividad = await fetch(_servidorapi+'maestroplanactividadeso');
        const jsonActividad = await responseActividad.json();
        const jsonOptionActividad =  [
          { value: "", label: "Actividad Plan de Accion" },
          ...jsonActividad.map(item => ({ value: item.id_planactividad, label: item.nombre })),
        ];
        setOptionsActividades(jsonOptionActividad);
       
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };

    fetchData();
  }, []); // El segundo argumento del useEffect, [] en este caso, asegura que se ejecute solo una vez al cargar la página


  return (
    <div className="container">
    <div className='px-6 py-4'>
       <Button onClick={handleAddClick} variant="primary">Actividades Operativas</Button>
      <div className='my-2 d-flex justify-content-end'>
      <div className="position-relative">
  <div className="input-group">
    <DebouncedInput
      type="text"
      value={globalFilter ?? ''}
      onChange={value => setGlobalFilter(String(value))}
      className="form-control"
      placeholder="Buscar..."
    />
    <button className="btn btn-primary" type="button">
      <FontAwesomeIcon icon={faSearch} className="text-light" />
    </button>
  </div>
</div>
      </div> 
      <div className='overflow-auto'>
          <table className='table table-striped w-full min-w-[560px]'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="border-bottom border-gray-300 text-gray-600 bg-gray-100">
                  {headerGroup.headers.map(header => (
                    <th key={header.id} className="py-2 px-4 text-left text-uppercase">
                      {header.isPlaceholder
                        ? null
                        :
                        <div
                          className={classNames({
                            'cursor-pointer select-none d-flex justify-content-between': header.column.getCanSort(),
                          })}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: <FontAwesomeIcon icon={faArrowUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />,
                            desc: <FontAwesomeIcon icon={faArrowDown} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          }[header.column.getIsSorted()] ?? (header.column.getCanSort() ? <FontAwesomeIcon icon={faChevronUp} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
                          : null)}
                        </div>
                      }
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="text-gray-600 hover:bg-slate-100">
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="py-2 px-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-between space-y-4 text-center'>
          <div className='d-flex align-items-center gap-2'>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}>
              <FontAwesomeIcon icon={faChevronLeft} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
      
            {table.getPageOptions().map((value, key) => (
              <button key={key}
                className={classNames({
                  "btn btn-light rounded border border-gray-300 font-weight-bold disabled:hover:bg-white disabled:hover:text-gray-300": true,
                  "bg-indigo-200 text-indigo-700": value === table.getState().pagination.pageIndex
                })}
                onClick={() => table.setPageIndex(value)}>
                {value + 1}
              </button>
            ))}
      
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}>
            <FontAwesomeIcon icon={faChevronRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
            <button
              className='btn btn-light rounded border border-gray-300 disabled:hover:bg-white disabled:hover:text-gray-300'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}>
              <FontAwesomeIcon icon={faAngleDoubleRight} className='fa-fw' style={{ width: '0.75rem', height: '0.75rem' }} />
            </button>
          </div>
          <div className='text-gray-600 font-weight-bold'>
            {getStateTable().firstIndex}&nbsp;
            a {getStateTable().lastIndex}&nbsp;
            de {getStateTable().totalRows} 
          </div>
          <div className="d-inline-block">
            <select
              className="form-select text-muted border border-muted rounded-pill outline-primary py-2"
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}>
            <option value="5">5 pág.</option>
            <option value="10">10 pág.</option>
            <option value="20">20 pág.</option>
            <option value="25">25 pág.</option>
            <option value="50">50 pág.</option>
          </select>
          </div>
     </div>
     <Modal show={showModal} onHide={() => setShowModal(false)}>
       <Modal.Header closeButton>
         <Modal.Title>{isEditMode ? 'Editar Actividad' : 'Agregar Actividad'}</Modal.Title>
       </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formLugar">
              <Form.Label>Nombre Corto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre Corto"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              />
            </Form.Group>
             <Form.Group controlId="formDescripcion">
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              as="textarea" 
              rows={3} 
              placeholder="Descripcion"
              value={descripcion} 
              onChange={e => setDescripcion(e.target.value)} 
            />
            
          </Form.Group>
            <Form.Group controlId="formUserType">
              <Form.Label>Actividad Plan de Accion</Form.Label>
              <Form.Control
                as="select"
                value={id_actividad}
                onChange={e => setIdActividad(e.target.value)}
              >
                {optionsActiviadades.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formUserType2">
              <Form.Label>Dependencia</Form.Label>
              <Form.Control
                as="select"
                value={id_dependencia}
                onChange={e => setIdDependencia(e.target.value)}
              >
                {optionsDependencias.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formUserType3">
              <Form.Label>Responsable</Form.Label>
              <Form.Control
                as="select"
                value={id_responsable}
                onChange={e => setIdResponsable(e.target.value)}
              >
                {optionsResponsables.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formLugar3">
              <Form.Label>Presupuesto</Form.Label>
              <Form.Control
                type="text"
                placeholder="Valor"
                value={valor}
                onChange={e => setValor(e.target.value)}
              />
            </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelClick}>Cancelar</Button>
          <Button variant="primary" onClick={isEditMode ? handleSaveEditClick : handleSaveClick}>{isEditMode ? 'Actualizar' : 'Guardar'}</Button>
        </Modal.Footer>
      </Modal>
    </div>
    </div>
  )
}

export default Actividadesoperativas