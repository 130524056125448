import React, { Component } from 'react';
import {BsFillMapFill} from 'react-icons/bs'
import { Link } from 'react-router-dom';

const _servidorapi = process.env.REACT_APP_URL_API;

class ComProyectos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datap: [],
      valorproyecto: 0,
    };
  }

  calcularProyecto = () => {
    let totalp = 0;
    for (const item of this.state.datap) {
      totalp += 1; // Asume que el campo se llama "valor"
    }
    return totalp;
  };


  fetchDatap = async () => {
    try {
      const responsep = await fetch(_servidorapi + 'maestroproyectos');
      const jsonDatap = await responsep.json();
      this.setState({ datap: jsonDatap });
      const totalp = this.calcularProyecto();
      this.setState({ valorproyecto: totalp });
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    }
  };

  componentDidMount() {
    this.fetchDatap();
  }

  render() {
    return (
      <div>
        <div className='card' style={{ backgroundColor: 'navy', color: 'white' }}>
          <Link to="/comproyectodetalle" className="card-link">
            <div className='card-inner'>
              <h3>Proyectos</h3>
              <BsFillMapFill className='card-icon'/>
            </div>
            <h1>{this.state.valorproyecto}</h1>
          </Link>
        </div>
      </div>
    );
  }
}

export default ComProyectos;

